import { ENTER } from '@angular/cdk/keycodes';
import { BindObservable } from 'bind-observable';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { filter, finalize, first } from 'rxjs/operators';
import { makeEnum } from 'shared/utils/make-enum';
import { isElement, nextAnimationFrame, uniqueid } from 'shared/utils/utils';

export const FormatBlockCommands = makeEnum('h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'break');
export type FormatBlockCommands = keyof typeof FormatBlockCommands;
export const NoPayloadCommand = makeEnum(
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'superscript',
    'subscript',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'indent',
    'outdent',
    'insertUnorderedList',
    'insertOrderedList',
    'insertHorizontalRule',
    'removeFormat',
    'undo',
    'redo',
);
export type NoPayloadCommand = keyof typeof NoPayloadCommand;

export const Command = makeEnum(...[...Object.values(FormatBlockCommands), ...Object.values(NoPayloadCommand)]);
export type Command = keyof typeof Command;
export type ContentState = { [key in Command]: boolean };

const haveParent = (n: Node, p: Element) =>
    n ?
        n === p
            ? true
            : haveParent(n.parentElement, p)
        : false;

export class TextEditorService {
    static activeInstance: TextEditorService;

    private savedSelection?: Range;

    selectionProperties = {
        rangeSelected: false,
        linkSelected: false,
        blockSelected: <FormatBlockCommands | undefined>undefined,
        selectionBackgroundColor: <string | undefined>undefined,
        selectionForegroundColor: <string | undefined>undefined,
    };

    private contentBlockId: string;

    @BindObservable()
    contentState: ContentState = {} as any;
    contentState$: Observable<ContentState>;

    private sub = new Subscription();
    private observer: MutationObserver;

    constructor(private contentBlock: HTMLElement, private multiline = true) {
        this.contentBlockId = `content${uniqueid()}`;
        this.contentBlock.id = this.contentBlockId;
        document.execCommand('defaultParagraphSeparator', false, 'p');

        const queryForContentStateIfFocusWithinContentBlock = merge(
            fromEvent(document, 'keyup'),
            fromEvent(document, 'keydown'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'click'),
        )
            .pipe(filter(
                () =>
                    haveParent(document.activeElement, this.contentBlock) ||
                    document.activeElement === this.contentBlock,
            ))
            .subscribe(() => this.queryForContentState());

        const saveSelection = merge(
            fromEvent(this.contentBlock, 'click'),
            fromEvent(this.contentBlock, 'keyup'),
            fromEvent(this.contentBlock, 'blur'),
            fromEvent(this.contentBlock, 'mouseout'),
        ).subscribe(() => this.saveSelection());

        const setAsActiveInstance = fromEvent(this.contentBlock, 'focusin')
            .subscribe(() => TextEditorService.activeInstance = this);

        this.sub.add(queryForContentStateIfFocusWithinContentBlock);
        this.sub.add(saveSelection);
        this.sub.add(setAsActiveInstance);

        if (multiline) {
            const onContentBlockChange = async (async = false) => {
                if (this.contentBlock.innerHTML.length === 0) {
                    this.beforeChange();
                    if (async) {
                        await nextAnimationFrame();
                    }
                    this.executeCommand('p');
                    this.saveSelection();
                }
            };
            this.observer = new MutationObserver(() => onContentBlockChange());
            this.observer.observe(this.contentBlock, { childList: true });

            fromEvent(this.contentBlock, 'focusin')
                .pipe(
                    first(),
                    finalize(() => onContentBlockChange(true)),
                )
                .subscribe();
        } else {
            const disableMultiline = fromEvent<KeyboardEvent>(this.contentBlock, 'keydown')
                .pipe(filter(ev => ev.keyCode === ENTER))
                .subscribe((ev) => ev.preventDefault());

            this.sub.add(disableMultiline);
        }
    }

    dispose() {
        this.sub.unsubscribe();
        if (this.observer) { this.observer.disconnect(); }
    }

    executeCommand(command: Command) {
        if (this.beforeChange()) { return; }

        const formatBlockCommands = Object.values(FormatBlockCommands);
        let isFormatBlockCommand = false;
        for (const cmd of formatBlockCommands) {
            if (cmd === command) {
                isFormatBlockCommand = true;
                break;
            }
        }
        if (isFormatBlockCommand) {
            document.execCommand('formatBlock', false, command);
            return;
        }
        document.execCommand(command, false, null);
    }

    insertHTML(html: string, shomehowImportantFix = true) {
        if (this.beforeChange()) { return; }
        document.execCommand('insertHTML', false, `${html}${shomehowImportantFix ? '&nbsp;' : ''}`);
    }

    insertImage(imageUrl: string, imageId: string) {
        if (this.beforeChange()) { return; }
        const html = `<img id="${imageId}" src=${imageUrl}>`;
        this.insertHTML(html, true);
    }

    insertColor(color: string, where: 'textColor' | 'backgroundColor'): void {
        if (this.beforeChange()) { return; }
        if (where === 'textColor') {
            document.execCommand('foreColor', false, color);
        } else {
            document.execCommand('hiliteColor', false, color);
        }
    }

    insertBreak() {
        if (this.beforeChange()) { return; }
        document.execCommand('insertHTML', false, `<p class="break">&nbsp;</p>`);
    }

    private saveSelection(): void {
        if (document.getSelection) {
            const sel = document.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                this.savedSelection = sel.getRangeAt(0);
            }
            this.setSelectionProperties(sel);
        } else if (document.getSelection && document.createRange) {
            this.savedSelection = document.createRange();
        } else {
            this.savedSelection = null;
        }
    }

    private restoreSelection(): boolean {
        if (this.savedSelection) {
            if (document.getSelection) {
                const sel = document.getSelection();
                sel.removeAllRanges();
                sel.addRange(this.savedSelection);
                return true;
            } else if (document.getSelection) {
                return true;
            }
        } else {
            return false;
        }
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private setSelectionProperties(sel: Selection) {
        this.selectionProperties = {
            blockSelected: undefined,
            linkSelected: false,
            rangeSelected: false,
            selectionBackgroundColor: null,
            selectionForegroundColor: null,
        };

        const node = sel.focusNode;

        if (!node || !haveParent(node, this.contentBlock) || node === this.contentBlock) {
            return;
        }

        const element = isElement(node) ? node : node.parentElement;

        this.selectionProperties.rangeSelected = sel.type === 'Range';

        const isLink = !!element.closest('a');
        if (isLink) {
            this.selectionProperties.linkSelected = true;
        }

        if (this.multiline) {
            this.selectionProperties.blockSelected = <FormatBlockCommands>element.closest(`#${this.contentBlockId} > *`)?.tagName.toLowerCase();
        }

        const computedStyle = getComputedStyle(element);
        this.selectionProperties.selectionBackgroundColor = this.selectionProperties.selectionBackgroundColor
            || computedStyle.backgroundColor;
        this.selectionProperties.selectionForegroundColor = this.selectionProperties.selectionForegroundColor
            || computedStyle.color;
    }

    private beforeChange() {
        if (TextEditorService.activeInstance !== this) {
            return true;
        }
        this.contentBlock.focus();
        this.restoreSelection();
        this.queryForContentState();
        return false;
    }

    private async queryForContentState() {
        await nextAnimationFrame();

        const commands = [].concat(...Object.values(Command));
        for (const c of commands) {
            this.contentState[c] = document.queryCommandState(c);
        }
        this.contentState = { ...this.contentState };
    }
}
