import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { OwlDateTime } from 'ng-pick-datetime/date-time/date-time.class';
import { addMonths } from 'date-fns';
import { BaseValueAccessor } from 'shared/modules/forms/base-value-accessor.class';

@Component({
    selector: 'app-month-picker',
    styles: [`
        .calendar {
            position: absolute;
            right: 296px;
        }
    `],
    template: `
        <div class="d-flex align-items-center justify-content-between h-100 position-relative">
            <span class="ps-2 change-month-button" *ngIf="value" (click)="prevMonth()"><i class="fa fa-chevron-left icon-button"></i></span>
            <span [owlDateTimeTrigger]="dt2" class="trigger">{{ (value | date : dateFormat) || '&nbsp;' }}</span>
            <span class="pe-2 change-month-button" *ngIf="value" (click)="nextMonth()"><i
                    class="fa fa-chevron-right icon-button"></i></span>
            <span *ngIf="!formControl.value && nullable" [owlDateTimeTrigger]="dt2">{{ placeholder }}</span>
            <i *ngIf="nullable" class="fa fa-times pe-2" (click)="formControl.patchValue(null)"></i>
        </div>
        <div class="calendar">
            <owl-date-time #dt2 [pickerType]="'calendar'" [startView]="'year'" (monthSelected)="pickMonth($event, dt2)"
                           panelClass="month-picker"></owl-date-time>
            <input [owlDateTime]="dt2" class="invisible disabled w-100 position-absolute" [owlDateTimeTrigger]="dt2"
                   style="max-height: 0; bottom: 0" [formControl]="formControl">
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthPickerComponent),
            multi: true,
        },
    ],
})
export class MonthPickerComponent extends BaseValueAccessor implements OnDestroy {
    @Input() dateFormat = 'LLL yyyy';
    @Input() nullable: boolean = false;
    @Input() placeholder = 'выбрать месяц';

    constructor() {
        super();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    pickMonth(d: Date, picker: OwlDateTime<Date>) {
        picker.select(d);
    }

    nextMonth() {
        this.value = addMonths(this.value, 1);
    }

    prevMonth() {
        this.value = addMonths(this.value, -1);
    }

}
