import { Injectable, QueryList, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavbarContentService {
  private _templates$: BehaviorSubject<TemplateRef<any>[]> = new BehaviorSubject(null);
  templates$ = this._templates$.asObservable();

  private _htmlClass$ = new BehaviorSubject<string>('');
  htmlClass$ = this._htmlClass$.asObservable();

  private _htmlColor$ = new BehaviorSubject<string>('');
  htmlColor$ = this._htmlClass$.asObservable();

  private toggleSubject$ = new BehaviorSubject<boolean>(false);
  toggle$ = this.toggleSubject$.asObservable();

  private showTogglerSubject$ = new BehaviorSubject<boolean>(true);
  showToggler$ = this.showTogglerSubject$.asObservable();

  setTemplate(template: TemplateRef<any>) {
    const newState = [...this._templates$.getValue() || [], template];
    this._templates$.next(newState);
    this._htmlClass$.next('');
  }

  setTemplates(templates: QueryList<TemplateRef<any>>) {
    const newState = [...this._templates$.getValue() || [], ...templates.toArray()];
    this._templates$.next(newState);
    this._htmlClass$.next('');
  }

  setClass(htmlClass: string) {
    this._htmlClass$.next(htmlClass);
  }

  setColor(htmlColor: string) {
    this._htmlColor$.next(htmlColor);
  }

  removeTemplate() {
    this._templates$.next(null);
  }

  toggle() {
    this.toggleSubject$.next(!this.toggleSubject$.value);
  }

  showToggler(val: boolean) {
    this.showTogglerSubject$.next(val);
  }

}
