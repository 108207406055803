import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label-wrapper',
    template: `
        <div class="{{ formGroupClass }}">
            <div class="label-wrapper">
                <ng-content></ng-content>
                <div class="label-inner-wrapper {{ alwaysOpened ? 'label-opened' : '' }}">
                    <label *ngIf="label">
                        {{ label }}
                    </label>
                </div>
            </div>
            <ng-content select=".lw-inside-form-group"></ng-content>
            <span *ngIf="required && requiredComment"><span class="small text-danger">{{ requiredComment }}</span></span>
        </div>
    `,
})
export class LabelWrapperComponent {
    @Input() label;
    @Input() alwaysOpened = false;
    @Input() formGroupClass;
    @Input() required = false;
    @Input() requiredComment;
}
