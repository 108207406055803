import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { DataProviderFactory } from 'shared/base/config-viewer/config-viewer-settings.models';
import { RecursiveTemplates } from 'shared/base/recursive-templates.base';
import { ColorizationService, IColorizationInterface } from 'shared/modules/table/colorization/colorization.service';
import { DefaultCell } from 'shared/modules/table/custom-cells/default-cell/default-cell';
import { ColumnHeading } from '../directives/column-heading.directive';
import { Footer } from '../directives/footer.directive';
import { CellDataFactory, CellDataPayload, ColumnConfig } from '../table-config.model';

@Component({
    selector: 'app-table',
    templateUrl: 'table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DataProviderFactory, useClass: CellDataFactory }
    ],
})
export class TableComponent extends RecursiveTemplates implements OnChanges, AfterContentInit {
    @Input() columns: ColumnConfig[] = [];
    @Input() rows: any[] = [];

    @Input() noRowsMessage = 'Table is empty';
    // TODO check
    @ContentChild(ColumnHeading) columnHeading: ColumnHeading;
    @ContentChild(Footer) footer: Footer;

    headerColumns: ColumnConfig[];

    private cache = new WeakMap<ColumnConfig, WeakMap<any, CellDataPayload>>();

    constructor(
        public cdRef: ChangeDetectorRef,
        public colorization: IColorizationInterface
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['columns'] && changes['columns'].currentValue) {
            this.columns = this.columns.map(it => {
                return (it.component === null || it.component === undefined) && (it.template === null || it.template === undefined)
                    ? { ...it, ...{ component: DefaultCell } }
                    : it;
            });

            this.headerColumns = this.columns
                .map(it => ({
                    ...it,
                    ...{ template: it.headerTemplate, component: it.headerComponent },
                }));
        }
    }

    getData(column: ColumnConfig, row: any, index: number): CellDataPayload {
        const setValueAndReturn = () => {
            const value = {
                column,
                row,
                index,
                template: column.template,
                component: column.component,
            };
            this.cache.get(column).set(row, value);
            return value;
        };

        if (!this.cache.has(column)) {
            this.cache.set(column, new WeakMap());
            return setValueAndReturn();
        }
        if (!this.cache.get(column).has(row)) {
            return setValueAndReturn();
        }
        return this.cache.get(column).get(row);
    }

    getRelatedTemplate$(col: ColumnConfig) {
        return this.templatesMap$.pipe(
            map(x => x[col.template] || x[col.prop]),
        );
    }

    ngAfterContentInit() {
        super.ngAfterContentInit();
    }

    trackByIndex(index: number) {
        return index;
    }
}
