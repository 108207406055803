import { NgModule, Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'validDate',
})
export class DatePipe implements PipeTransform {
    transform(date: string | number | Date): Date {
        if (!(date instanceof Date)) {
            return new Date(date);
        } else {
            return date;
        }
    }
}

@NgModule({
    declarations: [DatePipe],
    exports: [DatePipe],
})
export class NgDatePipeModule {}
