import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// tslint:disable:max-line-length
import { SearchbarPaginationWrapperCouplingModule } from 'portal/modules/shared/searchbar-pagination-wrapper-coupling/searchbar-pagination-wrapper-coupling.module';
// tslint:enable
import { ConfigViewerModule } from 'shared/base/config-viewer/config-viewer.module';
import { FormPartsModule } from 'shared/modules/forms/forms.module';
import { ModalModule } from 'shared/modules/modal/modal.module';
import { SearchbarModule } from 'shared/modules/searchbar/searchbar.module';
import { TableWithControlsModule } from 'shared/modules/table/table-with-controls/table-with-controls.module';

@NgModule({
  imports: [
    CommonModule,
    ConfigViewerModule,
    SearchbarModule,
    TableWithControlsModule,
    FormPartsModule,
    ModalModule,
    SearchbarPaginationWrapperCouplingModule,
  ],
  exports: [
    CommonModule,
    ConfigViewerModule,
    SearchbarModule,
    TableWithControlsModule,
    ModalModule,
    SearchbarPaginationWrapperCouplingModule,
  ],
})
export class SharedModule {
}
