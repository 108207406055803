import { Observable } from 'rxjs';

export abstract class CrudFecthService {
  constructor() {}

  abstract get(id: number): Observable<Object>;

  abstract update(payload): Observable<Object>;

  abstract create(payload): Observable<Object>;

  abstract delete(payload): Observable<Object>;
}
