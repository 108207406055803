import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadPermissionsGuard } from 'portal/services/load-permissions.guard';
import { RouteGuard } from 'portal/services/route.guard';
import { DefaultRouteReuseStrategy, VariableRouteReuseStrategy } from 'shared/route-reuse-strategies/variable-route-reuse-strategy';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SystemSettingsModal } from './pages/administration/system-settings/system-settings';
import { DismissialApplicationModal } from './pages/hr/dismissal-application/dismissal-application';
import { HrDocsComponent } from './pages/hr/hr-docs/hr-docs.component';
import { VacationApplicationModal } from './pages/hr/vacation-application/vacation.applcation';
import { LoginComponent } from './pages/login/login.component';
import { LoginUrl, PasswordChangeUrl } from './services/auth.service';


export const redirectToDashboard = 'redirect_for_hardware_123';
export const redirectToDashboardFactory = (toastr: ToastrService, router: Router) => () => {
    toastr.error('Страница не найдена');
    router.navigate(['/']);
    return false;
};

/*tslint:disable max-line-length*/
const routes: Routes = [
    // { path: HappyBirthdayIliaUrl, component: HappyBirthdayIliaComponent },
    { path: LoginUrl, component: LoginComponent },
    { path: PasswordChangeUrl, component: LoginComponent },

    {
        path: '', component: MainLayoutComponent,
        canActivate: [LoadPermissionsGuard],
        canActivateChild: [RouteGuard],
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
            { path: 'articles', loadChildren: () => import('./pages/articles/articles.module').then(m => m.ArticlesModule) },
            { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
            /*Main*/
            {
                path: 'employees',
                loadChildren: () => import('./pages/main/employees/employees.module').then(m => m.EmployeesModule),
                data: { only_fired: false },
            },
            // {
            //     path: 'employees-archive',
            //     loadChildren: () => import('./pages/main/employees/employees.module').then(m => m.EmployeesModule),
            //     data: { only_fired: true },
            // },
            {
                path: 'employees-treeview',
                loadChildren: () => import('./pages/main/employees-tree-view/employees-tree-view.module').then(m => m.EmployeesTreeViewModule),
            },

            // { path: 'booking-v2', loadChildren: () => import('./pages/main/booking/booking.module').then(m => m.NewBookingModule) },
            // { path: 'it-tasks', loadChildren: () => import('./pages/main/it-tasks/it-tasks.module').then(m => m.ItTasksModule) },
            { path: 'it-tasks', loadChildren: () => import('./pages/main/it-tasks-v2/it-tasks-v2.module').then(m => m.ItTasksV2Module) },
            {
                path: 'service-desk',
                loadChildren: () => import('./pages/main/service-desk/service-desk.module').then(m => m.ServiceDeskModule),
            },
            {
                path: 'company-structure',
                loadChildren: () => import('./pages/main/company-structure/company-structure.module').then(m => m.CompanyStructureModule),
            },
            { path: 'documents', loadChildren: () => import('./pages/main/documents/documents.module').then(m => m.DocumentsModule) },
            { path: 'mi-growth', loadChildren: () => import('./pages/main/mi-growth/mi-growth.module').then(m => m.MiGrowthModule) },
            { path: 'newbies', loadChildren: () => import('./pages/main/newbies/newbies.module').then(m => m.NewbiesModule) },
            { path: 'benefits', loadChildren: () => import('./pages/main/benefits/benefits.module').then(m => m.BenefitsModule) },
            { path: 'passes', loadChildren: () => import('./pages/main/passes/passes.module').then(m => m.PassesModule) },
            {
                path: 'password-gen',
                loadChildren: () => import('./pages/main/password-gen/password-gen.module').then(m => m.PasswordGenModule),
            },
            {
                path: 'percent-tracking',
                loadChildren: () => import('./pages/main/percent-tracking/percent-tracking.module').then(m => m.PercentTrackingModule),
            },
            { path: 'map', loadChildren: () => import('./pages/main/place-booking/old-map/map/map.module').then(m => m.MapModule) },
            {
                path: 'place-booking',
                loadChildren: () => import('./pages/main/place-booking/place-booking.module').then(m => m.PlaceBookingModule),
            },
            /*CEO board reports*/
            {
                path: 'ceo-board-reports',
                loadChildren: () => import('./pages/ceo-board/ceo-board.module').then(m => m.CeoBoardExternalReportModule),
            },
            /*HR*/
            { path: 'training', loadChildren: () => import('./pages/hr/training/training.module').then(m => m.TrainingModule) },
            { path: 'surveys', loadChildren: () => import('./pages/hr/surveys/surveys.module').then(m => m.SurveysModule) },
            { path: 'hr-docs', component: HrDocsComponent },
            { path: 'recruitment', loadChildren: () => import('./pages/hr/recruitment/recruitment.module').then(m => m.RecruitmentModule) },
            { path: 'vacations/send', canActivate: [VacationApplicationModal], component: AppComponent },
            { path: 'vacations', loadChildren: () => import('./pages/hr/vacations/vacations.module').then(m => m.VacationsModule) },
            {
                path: 'vacation-scheduler',
                loadChildren: () => import('./pages/main/vacation-scheduler/vacation-scheduler.module').then(m => m.VacationSchedulerModule),
            },
            { path: 'dismissals/send', canActivate: [DismissialApplicationModal], component: AppComponent },
            { path: 'dismissals', loadChildren: () => import('./pages/hr/dismissals/dismissals.module').then(m => m.DismissalsModule) },
            { path: 'staff-forms', loadChildren: () => import('./pages/hr/staff-forms/staff-forms.module').then(m => m.StaffFormsModule) },
            {
                path: 'welcome-letter',
                loadChildren: () => import('./pages/hr/welcome-letter/welcome-letter.module').then(m => m.WelcomeLetterModule),
            },
            {
                path: 'labor-book',
                loadChildren: () => import('./pages/hr/labor-books/labor-books.module').then(m => m.LaborBooksModule),
            },
            {
                path: 'sections',
                loadChildren: () => import('./pages/sections/sections.module').then(m => m.SectionsModule),
            },
            /*Administration*/
            { path: 'roles', loadChildren: () => import('./pages/administration/roles/roles.module').then(m => m.RolesModule) },
            { path: 'users', loadChildren: () => import('./pages/administration/users/users.module').then(m => m.UsersModule) },
            {
                path: 'computers',
                loadChildren: () => import('./pages/administration/computers/computers.module').then(m => m.ComputersModule),
            },
            { path: 'networks', loadChildren: () => import('./pages/administration/networks/networks.module').then(m => m.NetworksModule) },
            { path: 'info', loadChildren: () => import('./pages/administration/info/info.module').then(m => m.InfoModule) },
            {
                path: 'time-tracking',
                loadChildren: () => import('./pages/administration/time-tracking/time-tracking.module').then(m => m.TimeTrackingModule),
            },
            {
                path: 'booking-map-places',
                loadChildren: () => import('./pages/administration/booking-places/booking-places.module').then(m => m.BookingPlacesModule),
            },
            {
                path: 'activity-logs',
                loadChildren: () => import('./pages/administration/logging/logging.module').then(m => m.LoggingModule),
            },
            {
                path: 'sections-settings',
                loadChildren: () => import('./pages/administration/sections/sections.module').then(m => m.SectionsModule),
            },
            {
                path: 'banners-settings',
                loadChildren: () => import('./pages/administration/banners/banners.module').then(m => m.BannersModule),
            },
            { path: 'system-settings', canActivate: [SystemSettingsModal], component: AppComponent },
            /*USER-PROFILE*/
            { path: 'u', loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule) },
            /*CRUDs*/
            { path: 'agencies', loadChildren: () => import('./pages/cruds/agencies/agencies.module').then(m => m.AgenciesModule) },
            { path: 'clients', loadChildren: () => import('./pages/cruds/clients/clients.module').then(m => m.ClientsModule) },
            { path: 'companies', loadChildren: () => import('./pages/cruds/companies/companies.module').then(m => m.CompaniesModule) },
            {
                path: 'departments-portal',
                loadChildren: () => import('./pages/cruds/departments-portal/departments-portal.module').then(m => m.DepartmentsPortalModule),
            },
            {
                path: 'divisions-portal',
                loadChildren: () => import('./pages/cruds/divisions-portal/divisions-portal.module').then(m => m.DivisionsPortalModule),
            },
            /*tslint:disable:max-length*/
            {
                path: 'departments-for-percent-tracking',
                loadChildren: () => import('./pages/cruds/departments-for-percent-tracking/departments-for-percent-tracking.module').then(m => m.DepartmentsForPercentTrackingModule),
            },
            {
                path: 'departments-official',
                loadChildren: () => import('./pages/cruds/departments-official/departments-official.module').then(m => m.DepartmentsOfficialModule),
            },
            /*tslint:enable:max-length*/
            {
                path: 'positions-portal',
                loadChildren: () => import('./pages/cruds/positions-portal/positions-portal.module').then(m => m.PositionsPortalModule),
            },
            {
                path: 'positions-portal-ru',
                loadChildren: () => import('./pages/cruds/positions-ru/positions-ru.module').then(m => m.PositionsRuModule),
            },
            {
                path: 'positions-portal-official',
                loadChildren: () => import('./pages/cruds/positions-official/positions-official.module').then(m => m.PositionsOfficialModule),
            },
            {
                path: 'hr-docs-ref',
                loadChildren: () => import('./pages/cruds/hr-docs-ref/hr-docs-ref.module').then(m => m.HrDocsRefModule),
            },
            {
                path: 'vacation-types',
                loadChildren: () => import('./pages/cruds/vacation-types/vacation-types.module').then(m => m.VacationTypesModule),
            },
            { path: 'hardware', loadChildren: () => import('./pages/cruds/hardware/hardware.module').then(m => m.HardwareModule) },
            {
                path: 'gifts', children: [
                    {
                        path: 'catalogue',
                        loadChildren: () => import('./pages/administartive/gifts-catalogue/gifts-catalogue.module').then(m => m.GiftsCatalogueModule),
                    },
                    {
                        path: 'gift-category',
                        loadChildren: () => import('./pages/administartive/gift-category/gift-category.module').then(m => m.GiftCategoryModule),
                    },
                    {
                        path: 'gift-type',
                        loadChildren: () => import('./pages/administartive/gift-type/gift-type.module').then(m => m.GiftTypeModule),
                    },
                    {
                        path: 'occasions',
                        loadChildren: () => import('./pages/administartive/occasions/occasions.module').then(m => m.OccasionsModule),
                    },
                    {
                        path: 'recipients',
                        loadChildren: () => import('./pages/administartive/recipients/gift-recipients.module').then(m => m.GiftRecipientsModule),
                    },
                ],
            },
            { path: 'datafeed', loadChildren: () => import('./modules/datafeed/datafeed.module').then(m => m.DatafeedModule) },
            { path: '**', canActivate: [redirectToDashboard], component: AppComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
    })],
    exports: [RouterModule],
    providers: [
        VacationApplicationModal,
        DismissialApplicationModal,
        SystemSettingsModal,
        {
            provide: redirectToDashboard,
            useFactory: redirectToDashboardFactory,
            deps: [ToastrService, Router],
        },
        { provide: RouteReuseStrategy, useClass: VariableRouteReuseStrategy },
    ],
})
export class AppRoutingModule {
    constructor() {
        VariableRouteReuseStrategy.setRouteReuseStrategy(new DefaultRouteReuseStrategy());
    }
}
