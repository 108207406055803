/*tslint:disable component-class-suffix*/
import { Component, Input, Optional } from '@angular/core';
import { CellData } from 'shared/modules/table/table-config.model';

@Component({
    selector: 'app-default-cell',
    template: `
        <ng-container *ngIf="data.innerHtml">
            <a *ngIf="data.routerLink"
               [routerLink]="data.routerLink"
               [innerHtml]="data.innerHtml">

            </a>
            <span *ngIf="!data.routerLink"
                  [innerHtml]="data.innerHtml">
            </span>
        </ng-container>
        <ng-container *ngIf="data.value">
            <a *ngIf="data.routerLink"
               [routerLink]="data.routerLink">
                {{ data.value }}
            </a>
            <span *ngIf="!data.routerLink">
                {{ data.value }}
            </span>
        </ng-container>
    `,
})

export class DefaultCell {
    @Input() inputData: CellData;

    get data() {
        return this.inputData
            ? this.inputData
            : this.injectedData;
    }

    constructor(@Optional() public injectedData: CellData) {
    }
}
