/* tslint:disable */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-radio',
  template: `
      <app-label-wrapper
              [label]="to.label"
              [required]="to.required"
      >
          <div [formGroup]="form" class="mt-3">

              <div class="custom-control custom-radio"
                   *ngFor="let option of to.options | formlySelectOptions:field | async; let i = index;">
                  <input class="custom-control-input" type="radio"
                         [id]="id + '_' + i"
                         [name]="id"
                         [class.is-invalid]="showError"
                         [value]="option.value"
                         [formControl]="formControl"
                         [formlyAttributes]="field">
                  <label class="custom-control-label mb-2" [for]="id + '_' + i">
                      {{ option.label }}
                  </label>
              </div>
          </div>
      </app-label-wrapper>
  `,
})
export class FormlyFieldRadio extends FieldType {}
