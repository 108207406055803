<li *ngIf="hasOnlyLabel; else menu">{{ menuItem.label }}</li>

<ng-template #menu>
    <li [ngClass]="{'active': isActive(menuItem.route)}"
        (click)="openItem($event)"
        >
        <a *ngIf="menuItem.route && !menuItem.external"
           [class.has-arrow]="menuItem.children"
           [class]="menuItem.isOpened ? 'active' : ''"
           [routerLink]="menuItem.route">
            <i *ngIf="menuItem.icon" class="{{ menuItem.icon }} me-2"></i>
            <span>{{ menuItem.label }}</span>
        </a>
        <a *ngIf="!menuItem.route || menuItem.external === true"
           [class]="menuItem.isOpened ? 'active' : ''"
           [class.has-arrow]="menuItem.children"
           [href]="menuItem.route" (click)="!!menuItem.external" [target]="menuItem.external ? '_blank' : '_self'">
            <i *ngIf="menuItem.icon" class="{{ menuItem.icon }} me-2"></i>
            <span>{{ menuItem.label }}</span>
        </a>
        <ul *ngIf="menuItem.children" [@open]="menuItem.isOpened ? 'open' : 'close'" class="submenu">
            <app-sidebar-menu-item *ngFor="let subItem of menuItem.children"
                                   [menuItem]="subItem"></app-sidebar-menu-item>
        </ul>
    </li>
</ng-template>
