import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RecursiveTemplates } from 'shared/base/recursive-templates.base';
import { NamedTemplateDirective } from 'shared/base/template.directive';
import { ConfigViewerComponent } from './config-viewer.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    RecursiveTemplates,
    ConfigViewerComponent,
    NamedTemplateDirective,
  ],
  exports: [ConfigViewerComponent, NamedTemplateDirective],
})
export class ConfigViewerModule {
}
