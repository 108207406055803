import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

const adjustForTimezone = (date: Date) => {
    const newDate = new Date(+date);
    const timeOffsetInMS = date.getTimezoneOffset() * 60000;
    newDate.setTime(date.getTime() - timeOffsetInMS);
    return newDate;
};

export const fromPHPDate = (date: string) => {
    const [year, month, day] = date.split('-');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
};

export const toPHPDateTime = (v: Date) => adjustForTimezone(v).toISOString().split('T').join(' ').split('.')[0];
export const toPHPDate = (v: Date) => toPHPDateTime(v).split(' ')[0];

export const minutesToDate = (m: number) => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, m, 0);
};

export const dateToMinutes = (v: Date) => {
    return (v.getHours() * 60) + v.getMinutes();
};

export class FormUtils {

    static _markForm<T extends (FormGroup | FormArray)>(group: T, method: keyof T): void {
        const controls = Object.keys(group.controls).map(x => group.controls[x]);
        controls.forEach(x => {
            if (x instanceof FormArray || x instanceof FormGroup) {
                FormUtils._markForm(x, method as any);
            } else {
                x.updateValueAndValidity();
                x[method]();
            }
        });
    }

    static markFormPristine(group: FormGroup | FormArray): void {
        FormUtils._markForm(group, 'markAsPristine');
    }

    static markFormDirty(group: FormGroup | FormArray): void {
        FormUtils._markForm(group, 'markAsDirty');
    }

    static markFormPending(group: FormGroup | FormArray): void {
        FormUtils._markForm(group, 'markAsPending');
    }

    static markFormTouched(group: FormGroup | FormArray): void {
        FormUtils._markForm(group, 'markAsTouched');
    }

    static markFormUntouched(group: FormGroup | FormArray): void {
        FormUtils._markForm(group, 'markAsUntouched');
    }

    static getFormErrors(group: FormGroup | FormArray) {
        const allControls = Object.keys(group.controls).map(x => group.controls[x]);
        const controls = [];
        const groups = [];
        for (const control of allControls) {
            if (control instanceof FormArray || control instanceof FormGroup) {
                groups.push(control);
            } else {
                controls.push(control);
            }
        }
        const innerGroupErrors = groups.map(it => FormUtils.getFormErrors(it));
        const controlsErrors = controls.map(it => it.errors);
        return [group.errors, ...innerGroupErrors, ...controlsErrors].reduce((a, b) => ({ ...a, ...b }), {});
    }
}

export const walkThroughNestedFormlyConfigsWithKeys = (config: FormlyFieldConfig[], cb: (f: FormlyFieldConfig) => void) => {
    config.forEach(c => {
        if (c.key) {
            cb(c);
        }
        if (c.fieldGroup) {
            walkThroughNestedFormlyConfigsWithKeys(c.fieldGroup, cb);
        }
        if (c.fieldArray) {
            walkThroughNestedFormlyConfigsWithKeys([c.fieldArray], cb);
        }
    });
};
