import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';
/** TODO
 * Make not searchable by default
 */

/*tslint:disable component-class-suffix component-selector*/
@Component({
    selector: 'formly-field-help-block',
    template: `
        <p [innerHTML]="field.templateOptions['html']" class="form-text text-muted me-3 mb-3" style="margin-top: -10px"></p>
    `,
})
export class FormlyFieldHelpBlock extends FieldType {
    isObservable = isObservable;

    constructor() {
        super();
    }

}
