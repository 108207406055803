<div class="d-flex align-items-center" *ngIf="!isColumnHeader">
    <div *ngIf="controlCellService.selectable" class="custom-control custom-checkbox" style="margin-top: .2rem">
        <input type="checkbox" #checkbox class="custom-control-input" id="{{ cellData.index }}"
               (click)="toggleRow(cellData.index)">
        <label class="custom-control-label" for="{{ cellData.index }}"></label>
    </div>

    <i *ngFor="let button of (controlCellService.buttons$ | async); let i = index"
       class="{{ button.class }} mx-2 icon-button"
       container="body" boundariesElement="window" [tooltip]="button.hint"
       [placement]="'right'"
       (click)="buttonClicked(i)"
    ></i>
</div>
<ng-container *ngIf="isColumnHeader">
    <div *ngIf="controlCellService.selectable" class="custom-control custom-checkbox">
        <input type="checkbox" #checkbox class="custom-control-input" id="customCheck1"
               (click)="toggleAllRows($event)">
        <label class="custom-control-label" for="customCheck1"></label>
    </div>
</ng-container>
