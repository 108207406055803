import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { BannerApiModel } from 'portal/pages/administration/banners/banners.interfaces';
import { ArticlesFetchService } from 'portal/pages/articles/articles-fetch.service';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ApiResponse } from 'shared/interfaces/response';
import { EmployeeApiModel, SimpleEmployeeApiModel } from '../main/employees/employees.interfaces';

export interface UserInfo {
    id: number;
    login: string;
    name: string;
    email?: any;
    role_id: number;
    blocked: boolean;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
    is_employee: boolean;
    employee?: EmployeeApiModel;
}

export interface BirthdayApiModel {
    id: number;
    birth_month: number;
    birth_day: number;
    display_name: string;
    email: string;
    photo_url: string;
    portal_position_name: string;
    photo_file_guid: string;
}

export interface Birthday {
    id: number;
    birthday: Date;
    fullname: string;
    email: string;
    photo_url: string;
    portal_position_name: string;
    photo_file_guid?: string;
}

const convert = (b: BirthdayApiModel): Birthday => {
    const id = b.id;
    const fullname = b.display_name;
    const email = b.email;
    const portal_position_name = b.portal_position_name;
    const photo_file_guid = b.photo_file_guid;
    const photo_url = b.photo_url;
    const birthday = new Date;
    birthday.setMonth(b.birth_month - 1, b.birth_day);
    return { id, fullname, email, portal_position_name, birthday, photo_file_guid, photo_url };
};

@Injectable()
export class DashboardService {
    @BindObservable()
    userInfo: UserInfo;
    userInfo$!: Observable<UserInfo>;

    banners$: Observable<BannerApiModel[]> = this.http.get<ApiResponse<BannerApiModel[]>>('/api/v2/banner/available').pipe(
        map(it => it.data['items']),
        map(banners => banners.filter((banner) => banner.image ? !!banner.image.url : false)),
        catchError(() => of([])),
        shareReplay(1),
    );

    birthdays$ = this.http.get('/api/v2/company/employee/birthday/list')
        .pipe(
            map((it: any) => it.data.items),
            map(res => res.map(convert)),
            catchError(() => of([])),
            shareReplay(1),
        );

    todayBirthdays$ = this.birthdays$.pipe(
        map(bdays => bdays.filter(item => this.isToday(item.birthday))),
    );
    soonBirthdays$ = this.birthdays$.pipe(
        map(bdays => bdays.filter(item => !this.isToday(item.birthday))),
    );

    newbies$: Observable<SimpleEmployeeApiModel[]> = this.http.get<ApiResponse<SimpleEmployeeApiModel[]>>(`/api/v2/company/employee/list/newbies?last_days=30`).pipe(
        map(it => it.data),
        shareReplay(1),
    );

    buddies$: Observable<SimpleEmployeeApiModel[]> = this.http.get<ApiResponse<SimpleEmployeeApiModel[]>>(`/api/v2/company/employee/list/buddies?last_days=60`).pipe(
        map(it => it.data),
        shareReplay(1),
    );

    articles$ = this.articlesFetch.getList({ limit: 4 }).pipe(
        map(data => data.data.items),
        catchError(() => of([])),
        shareReplay(1),
    );

    today = new Date;

    constructor(private http: HttpClient, private articlesFetch: ArticlesFetchService) {}

    isToday(date: Date) {
        return date.getDate() === this.today.getDate() && date.getMonth() === this.today.getMonth();
    }
}
