import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, share, shareReplay, tap } from 'rxjs/operators';

export enum BreakpointsAliases {
  xs = 0,
  sm,
  md,
  lg,
  xl
}

const breakpoints = [
  { alias: 'xs', mediaQuery: '(min-width: 0px) and (max-width: 576px)', suffix: 'Xs', overlapping: false },
  { alias: 'gt-xs', overlapping: true, mediaQuery: '(min-width: 577px)', suffix: 'GtXs' },
  { alias: 'lt-sm', overlapping: true, mediaQuery: '(max-width: 576px)', suffix: 'LtSm' },
  { alias: 'sm', mediaQuery: '(min-width: 577px) and (max-width: 768px)', suffix: 'Sm', overlapping: false },
  { alias: 'gt-sm', overlapping: true, mediaQuery: '(min-width: 769px)', suffix: 'GtSm' },
  { alias: 'lt-md', overlapping: true, mediaQuery: '(max-width: 768px)', suffix: 'LtMd' },
  { alias: 'md', mediaQuery: '(min-width: 769px) and (max-width: 992px)', suffix: 'Md', overlapping: false },
  { alias: 'gt-md', overlapping: true, mediaQuery: '(min-width: 993px)', suffix: 'GtMd' },
  { alias: 'lt-lg', overlapping: true, mediaQuery: '(max-width: 992px)', suffix: 'LtLg' },
  { alias: 'lg', mediaQuery: '(min-width: 993px) and (max-width: 1199px)', suffix: 'Lg', overlapping: false },
  { alias: 'gt-lg', overlapping: true, mediaQuery: '(min-width: 1200px)', suffix: 'GtLg' },
  { alias: 'lt-xl', overlapping: true, mediaQuery: '(max-width: 1199px)', suffix: 'LtXl' },
  { alias: 'xl', mediaQuery: '(min-width: 1200px) and (max-width: 5000px)', suffix: 'Xl', overlapping: false },
  { alias: 'gt-xl', mediaQuery: '(min-width: 1200px)', suffix: 'GtXl', overlapping: false },
];


@Injectable()
export class MediaQueryService {
  obs$ = this.breakPointObserver
    .observe(breakpoints.map(it => it.mediaQuery))
    .pipe(
      map(it => it.breakpoints),
      shareReplay(1),
    );

  constructor(private breakPointObserver: BreakpointObserver) {}

  equal(bp: BreakpointsAliases) {
    return this.obs$.pipe(
      map(breakpointsMatch => breakpointsMatch[breakpoints.find(it => it.alias === BreakpointsAliases[bp]).mediaQuery]),
      distinctUntilChanged(),
    );
  }

  moreThen(bp: BreakpointsAliases) {
    return this.obs$.pipe(
      map(breakpointsMatch => breakpointsMatch[breakpoints.find(it => it.alias === `gt-${BreakpointsAliases[bp]}`).mediaQuery]),
      distinctUntilChanged(),
    );
  }

  lessThen(bp: BreakpointsAliases) {
    return this.obs$.pipe(
      map(breakpointsMatch => breakpointsMatch[breakpoints.find(it => it.alias === `lt-${BreakpointsAliases[bp]}`).mediaQuery]),
      distinctUntilChanged(),
    );
  }

  moreOrEqual(bp: BreakpointsAliases) {
    return this.lessThen(bp).pipe(map(x => !x));
  }

  lessOrEqual(bp: BreakpointsAliases) {
    return this.moreThen(bp).pipe(map(x => !x));
  }
}
