import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ImageCropperComponent } from './image-cropper.component';

@NgModule({
  imports: [CommonModule, ImageCropperModule],
  declarations: [ImageCropperComponent],
  exports:      [ImageCropperComponent],
})
export class MyImageCropperModule {}
