<div class="centered-x loader {{ photoLoaded ? 'opacity-0' : '' }}"></div>
<div class="centered-x image-container {{ photoLoaded ? 'loaded' : '' }}">
    <button class="btn btn-light p-1 close-button" (click)="modalRef.hide()">
        <i class="fa fa-fw fa-times"></i>
    </button>
    <img (error)="photoSrc = './assets/images/no-image.png'"
         (load)="photoLoaded = true"
         [src]="safe(photoSrc)"
    />
</div>
