import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from 'portal/services/permissions.service';
import { mapTo } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LoadPermissionsGuard implements CanActivate {

  constructor(private permissions: PermissionsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissions.loadPermissions().pipe(mapTo(true));
  }
}
