import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { flatMap } from 'lodash-es';
import { Observable } from 'rxjs';
import { PermissionsService } from 'portal/services/permissions.service';
import { MENU_ITEMS } from 'portal/modules/navigation/navigation.service';
import { MenuItem } from 'shared/modules/navigation/sidebar-menu-item/menu-item.model';
import { first, map, shareReplay, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouteGuard implements CanActivateChild {

  menuPermissions$: Observable<{ [key: string]: boolean }>;

  constructor(
    private permissions: PermissionsService,
    private router: Router,
    @Inject(MENU_ITEMS) menuItems$: Observable<MenuItem[]>) {
    this.menuPermissions$ = menuItems$.pipe(
      map(data =>
        flatMap(data, item => [item, ...(!!item.children ? item.children : [])])
          .filter(item => item.hasOwnProperty('permission'))
          .reduce((start, next) => ({ ...start, ...{ [next.route]: this.permissions.getPermissionValue(next.permission) } }), {}),
      ),
      shareReplay(1),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const currentURL = state.url.split('?').splice(0, 1)[0];
    return this.menuPermissions$.pipe(
      first(),
      map(p => !p.hasOwnProperty(currentURL) || p[currentURL]),
      tap(val => {
        if (!val) {
          this.router.navigate(['/404']);
        }
      }),
    );
  }
}
