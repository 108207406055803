import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FilesListComponent } from './files-list.component';

@NgModule({
  imports: [CommonModule],
  exports: [FilesListComponent],
  declarations: [FilesListComponent],
})
export class FilesListModule {}
