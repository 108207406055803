import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';

/*tslint:disable component-class-suffix component-selector*/
@Component({
  selector: 'field-multiselect',
  template: `
      <ng-select
              [multiple]="true"
              [items]="isObservable(to.options) ? (to.options | async) : to.options"
              [loading]="isObservable(to.options) ? !(to.options | async) : false"
              [class.loading]="isObservable(to.options) ? !(to.options | async) : false"
              [formControl]="formControl"
              [closeOnSelect]="to.closeOnSelect"
              bindValue="value">

          <ng-template ng-option-tmp let-item="item">
              <span class="ng-option-label" [title]="item.label">{{item.label}}</span>
          </ng-template>

          <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span aria-hidden="true" class="ng-value-icon left" (click)="clear(item)">×</span>
              <span class="ng-value-label text-overflow-ellipsis-title" [title]="item.label"></span>
          </ng-template>

          <ng-container *ngIf="to.checkboxes">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <div class="custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" id="item-{{index}}" [ngModel]="item$.selected">
                      <label class="custom-control-label" [title]="item.label" id="item-{{index}}">
                          {{ item.label }}
                      </label>
                  </div>
              </ng-template>
          </ng-container>
      </ng-select>
  `,
  styles: [`
    :host ::ng-deep .ng-value-container {
        width: 1px;
    }
    :host ::ng-deep .ng-value {
        overflow: hidden;
        display: flex;
    }
  `],
})
export class Multiselect extends FieldType {
  to: any;
  isObservable = isObservable;
}
