<app-pagination-wrapper [query$]="query$">
    <app-table-with-controls [columns]="columns"
                             [buttons]="buttons"
                             (selectionChanged)="setSelections($event)"
                             (buttonClicked)="update($event)"
    >

        <ng-template templateName="period" let-data>
            {{ data.row.date_from | validDate | date }} - {{ data.row.date_to | validDate | date }}
        </ng-template>
        <ng-template templateName="weekday" let-data>
            {{ DaysOfWeek[data.value] }}
        </ng-template>

    </app-table-with-controls>
</app-pagination-wrapper>

<ng-template #toNavbar>
    <div class="mx-3 d-flex flex-grow-1">
        <button class="btn btn-navbar" (click)="goBack()">
            <i class="fa fa-arrow-left"></i>
            Вернуться
        </button>
        <button class="btn btn-navbar" (click)="create()">
            <i class="fa fa-plus"></i>
            Добавить
        </button>
        <button *ngIf="idsToDelete.length" class="btn btn-navbar btn-navbar-danger" (click)="delete()">
            <i class="fa fa-trash"></i>
            Удалить
        </button>
    </div>
</ng-template>
