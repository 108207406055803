import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-docs',
  templateUrl: './hr-docs.component.html',
})
export class HrDocsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
