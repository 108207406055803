<ng-content></ng-content>
<ng-container *ngIf="count && count > limit">
    <div class="table-footer">
        <app-table-paginator
                [count]="count"
                [(offset)]="offset"
                [(rowsOnPage)]="limit"
        ></app-table-paginator>
    </div>
</ng-container>

<div class="loader centered animated {{ (isLoading$ | async) ? 'my-show' : 'my-hide' }}"></div>
