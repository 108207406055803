import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEqual, orderBy } from "lodash-es";
import { ToastrService } from "ngx-toastr";
import { BookingHeaderCellComponent } from "portal/pages/main/booking/booking-header-cell/booking-header-cell";
import { ServiceDeskRequest } from "portal/pages/main/service-desk/service-desk.interfaces";
import {
    PermissionsService,
    permStub
} from "portal/services/permissions.service";
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from "rxjs";
import {
    distinctUntilChanged,
    finalize,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    take
} from "rxjs/operators";
import { ListResponse } from "shared/interfaces/list";
import { ApiResponse } from "shared/interfaces/response";
import { ModalService } from "shared/modules/modal/modal.service";
import {
    CellData,
    ColumnConfig
} from "shared/modules/table/table-config.model";
import { isNullOrUndefined, Utils } from "shared/utils/utils";
import {
    Booking,
    BookingRow,
    PlaceCol,
    SelectedCells,
    TimeTableResponse
} from "./booking.interfaces";
import { TimeFromTimeToCell } from "./time-from-time-to-cell/time-from-time-to-cell";

// noinspection DuplicatedCode
@Injectable()
export class BookingService {
    private selectedDateSubject$ = new BehaviorSubject<Date>(Utils.now);
    cellSize = 0;

    get selectedDate$() {
        return this.selectedDateSubject$.asObservable();
    }

    private selectedCellsSubject$ = new BehaviorSubject<SelectedCells>({});

    rowsAndColumns$: Observable<{
        columns: ColumnConfig<PlaceCol>[];
        rows: BookingRow[];
    }> = this.selectedDateSubject$.asObservable().pipe(
        switchMap(selectedDate => {
            return combineLatest([
                this.http.post<ApiResponse<TimeTableResponse>>(
                    "/api/booking/get_booking_timetable",
                    { date: Utils.dateToBackend(selectedDate) }
                ),
                this.http.get<ListResponse<ServiceDeskRequest>>(
                    "/api/v2/service-desk/list",
                    {
                        params: {
                            skip_user_check: "1",
                            category_id: "1",
                            search_string: Utils.dateToBackend(selectedDate),
                            limit: "30"
                        }
                    }
                )
            ]).pipe(
                map(([bookings, serviceDeskRequests]) => {
                    const placeColumns: ColumnConfig<
                        PlaceCol
                    >[] = bookings.data.header.map((data: PlaceCol) => ({
                        prop: `item.${data.id}`,
                        headerComponent: BookingHeaderCellComponent,
                        component: this.cmp,
                        data
                    }));
                    placeColumns.push({
                        prop: `item.1000`,
                        headerComponent: BookingHeaderCellComponent,
                        component: this.cmp,
                        data: {
                            id: 1000,
                            name: "Переход",
                            private: false,
                            bookable: false,
                            internal_phone: null,
                            capacity: "От 15 человек"
                        }
                    });
                    const columns: ColumnConfig<PlaceCol>[] = [
                        {
                            name: "Время",
                            component: TimeFromTimeToCell
                        },
                        ...placeColumns
                    ];
                    this.prepareSelectedCellsDataStructure(placeColumns);

                    bookings.data.rows.forEach(r => {
                        orderBy(
                            serviceDeskRequests.data.items,
                            "timeFrom"
                        ).forEach(d => {
                            if (
                                d.payload.time_from >= r.time_to ||
                                r.time_from >= d.payload.time_to
                            ) {
                                if (
                                    !!isNullOrUndefined(r["places"]["1000"]) ===
                                    false
                                ) {
                                    return;
                                }
                                r["places"]["1000"] = null;
                                return;
                            }
                            r["places"]["1000"] = {
                                user_id: d.applicant_user_id,
                                user_name: `${d.payload.purpose}`,
                                employee_id: d.applicant_employee_id
                            };
                        });
                    });
                    const rows = this.computeRows(bookings);

                    this.computeRowSpans(rows);
                    this.cellSize = rows[0].time_to - rows[0].time_from;
                    return { columns, rows };
                })
            );
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    bookings$: Observable<
        Booking[]
    > = this.selectedCellsSubject$.asObservable().pipe(
        map(selectedCells => {
            let bookings: Booking[] = [];
            // Iterate over dates and map-places
            Object.keys(selectedCells).forEach(placeId => {
                // Constant part of query
                const bookingTemplate: Booking = {
                    date: Utils.dateToBackend(this.selectedDateSubject$.value),
                    place_id: parseInt(placeId, 10),
                    time_from: 0,
                    time_to: 0
                };
                // array of timeFrom F.E. [600, 630, 750]
                const timeFromArray = Object.keys(selectedCells[placeId])
                    .filter(timeFrom => selectedCells[placeId][timeFrom])
                    .map(timeFrom => parseInt(timeFrom, 10))
                    .sort((a, b) => (a > b ? 1 : -1));
                // if some cells are selected
                if (timeFromArray.length) {
                    const bookingsForPlace = timeFromArray.reduce<Booking[]>(
                        (arrayOfBookings, time_from) => {
                            const lastEntry =
                                arrayOfBookings[arrayOfBookings.length - 1];

                            const isFirstIteration = lastEntry.time_from === 0;
                            const nextCellIsAfterOther =
                                lastEntry.time_to === time_from;

                            if (isFirstIteration) {
                                lastEntry.time_from = time_from;
                                lastEntry.time_to = time_from + this.cellSize;
                            } else if (nextCellIsAfterOther) {
                                lastEntry.time_to = time_from + this.cellSize;
                            } else {
                                // create new entry
                                arrayOfBookings.push({
                                    ...bookingTemplate,
                                    ...{
                                        time_from,
                                        time_to: time_from + this.cellSize
                                    }
                                });
                            }
                            return arrayOfBookings;
                        },
                        [{ ...bookingTemplate }]
                    );

                    bookings = bookings.concat(bookingsForPlace);
                }
            });
            return bookings;
        })
    );

    showCancelBookings$ = this.getShowCancelBookings$();

    constructor(
        private http: HttpClient,
        private cmp: any,
        private permissionsService: PermissionsService,
        private modalService: ModalService,
        private toastr: ToastrService
    ) {}

    setSelectedDate(val: Date) {
        this.selectedDateSubject$.next(val);
    }

    private computeRows(res: any) {
        return res.data.rows.map(x => {
            return {
                ...x.places,
                time_from: x.time_from,
                time_to: x.time_to,
                rowspan: {}
            };
        });
    }

    private computeRowSpans(rows: BookingRow[]) {
        const placesIds = Object.keys(rows[0])
            .map(x => parseInt(x, 10))
            .filter(x => !!x);

        const memory: {
            [placeId: number]: { value?: string; index?: number };
        } = placesIds
            .map(placeId => ({ [placeId]: {} }))
            .reduce((a, b) => ({ ...a, ...b }), {});

        for (let i = 0; i < rows.length; i++) {
            for (const placeId of placesIds) {
                const memoryForPlace = memory[placeId];
                const rowValue = rows[i][placeId];
                if (isNullOrUndefined(rowValue)) {
                    // Free cell
                    memoryForPlace.value = undefined;
                    memoryForPlace.index = undefined;
                } else if (isEqual(memoryForPlace.value, rowValue)) {
                    // Current cell same as memoized
                    rows[i].rowspan[placeId] = "none";
                    rows[memory[placeId].index].rowspan[placeId] =
                        (rows[memory[placeId].index].rowspan[
                            placeId
                        ] as number) + 1 || 2;
                } else {
                    // memoize cell
                    memoryForPlace.value = rowValue;
                    memoryForPlace.index = i;
                }
            }
        }
    }

    // tslint:disable-next-line:max-line-length
    createTemplateInfoForm$(): Observable<{
        minTimeFrom: number;
        maxTimeFrom: number;
        places: { label: string; value: number }[];
        minTimeTo: number;
        step: number;
        maxTimeTo: number;
    }> {
        return this.rowsAndColumns$.pipe(
            map(({ rows, columns }) => {
                const places = columns
                    .filter(col => col.data)
                    .map(col => ({ label: col.data.name, value: col.data.id }));
                const minTimeFrom = rows[0].time_from;
                const minTimeTo = rows[0].time_to;
                const maxTimeFrom = rows[rows.length - 1].time_from;
                const maxTimeTo = rows[rows.length - 1].time_to;
                const step = this.cellSize;
                return {
                    minTimeFrom,
                    minTimeTo,
                    maxTimeFrom,
                    maxTimeTo,
                    step,
                    places
                };
            })
        );
    }

    isCellSelected$(data: CellData<BookingRow, PlaceCol>): Observable<boolean> {
        const placeId = data.column.data.id;
        const time = data.row.time_from;
        return this.selectedCellsSubject$.pipe(
            map(
                selectedCells =>
                    selectedCells[placeId] && selectedCells[placeId][time]
            ),
            distinctUntilChanged()
        );
    }

    isCellAvaliable(data: CellData<BookingRow, PlaceCol>) {
        if (data.value) {
            return false;
        }

        // if (data.column.data.hasOwnProperty("bookable")) {
        //     return data.column.data.bookable;
        // }

        if (Utils.isToday(this.selectedDateSubject$.value)) {
            const timeNow = new Date();
            const timeInRow = new Date();
            timeInRow.setHours(0, 0, 0, 0);
            timeInRow.setMinutes(data.row.time_from + this.cellSize);
            return timeNow < timeInRow;
        }
        return true;
    }

    toggleSelected(data: CellData<BookingRow, PlaceCol>) {
        const placeId = data.column.data.id;
        const time = data.row.time_from;
        const selectedCells = this.selectedCellsSubject$.value;
        selectedCells[placeId][time] = !selectedCells[placeId][time];

        this.selectedCellsSubject$.next(selectedCells);
    }

    private prepareSelectedCellsDataStructure(
        placeColumns: ColumnConfig<PlaceCol>[]
    ) {
        placeColumns.forEach(col => {
            const placeId = col.data.id;
            if (isNullOrUndefined(this.selectedCellsSubject$.value)) {
                this.selectedCellsSubject$.next({});
            }
            if (isNullOrUndefined(this.selectedCellsSubject$.value[placeId])) {
                this.selectedCellsSubject$.value[col.data.id] = {};
            }
        });
    }

    makeBookings() {
        return this.bookings$.pipe(
            take(1),
            switchMap(x => of(...x)),
            mergeMap((booking: Booking) => {
                if (booking.place_id === 1000) {
                    return this.modalService.createForm({
                        title: "Уточнить бронирование перехода",
                        form: [
                            {
                                key: "number_of_people",
                                type: "input",
                                templateOptions: {
                                    label: "Количество приглашенных",
                                    required: true,
                                    type: "number"
                                }
                            },
                            {
                                type: "help-block",
                                templateOptions: {
                                    html: `<span>от 15 человек</span>`
                                }
                            },
                            {
                                key: "purpose",
                                type: "input",
                                templateOptions: {
                                    label: "Цель",
                                    required: true,
                                    type: "text"
                                }
                            },
                            {
                                type: "help-block",
                                templateOptions: {
                                    html: `<span>В комментарии укажите, что потребуется для встречи (вода, печенье), как расставить столы и тд. Обращаем ваше внимание, что для организации IT-поддержки встречи необходимо подать отдельную заявку в IT</span>`
                                }
                            },
                            {
                                type: "textarea",
                                key: "description",
                                templateOptions: {
                                    label: "Комментарий",
                                    rows: 3
                                }
                            }
                        ],
                        onSubmit: (form: { number_of_people; purpose, description }) => {
                            return this.http
                                .post("/api/v2/service-desk", {
                                    category_id: 1,
                                    type_id: 6,
                                    office_id: 1,
                                    title: "Бронь перехода",
                                    description: form.description,
                                    payload: {
                                        number_of_people: form.number_of_people,
                                        purpose: form.purpose,
                                        office_place: "Кунцево Плаза",
                                        date: booking.date,
                                        time_from: booking.time_from,
                                        time_to: booking.time_to
                                    }
                                })
                                .pipe(
                                    finalize(() =>
                                        this.toastr.info(
                                            "Бронь перехода появится, когда будет принята сотрудниками АХО"
                                        )
                                    )
                                );
                        }
                    });
                }
                return this.http.post("/api/booking/make_booking", booking);
            }),
            finalize(() => this.reloadTimetable())
        );
    }

    cancelBookings(reqBodies: { id: number }[]) {
        return forkJoin(
            reqBodies.map(body =>
                this.http.post("/api/booking/cancel_booking", body)
            )
        ).pipe(
            switchMap(() => {
                this.reloadTimetable();
                return this.rowsAndColumns$.pipe(take(2));
            })
        );
    }

    reloadTimetable() {
        this.selectedDateSubject$.next(this.selectedDateSubject$.value);
        this.showCancelBookings$ = this.getShowCancelBookings$();
    }

    private getShowCancelBookings$(): Observable<boolean> {
        const canCancelAny = this.permissionsService.getPermissionValue(
            permStub.booking.timetable.cancelAny
        );
        return canCancelAny
            ? of(true)
            : this.http
                  .post<any>("/api/booking/get_booking_list", {})
                  .pipe(map(res => !!res.data.total));
    }
}
