import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudFecthService } from 'portal/chunks/default-crud/abstract-crud-fetch.service';
import { DefaultCrudSettingsConstructor } from 'portal/chunks/default-crud/default-crud.settings';
import { map } from 'rxjs/operators';
import { ListPayload, ListResponse } from 'shared/interfaces/list';
import { ApiResponse } from 'shared/interfaces/response';
import { serializeGet } from 'shared/utils/utils';

interface CRUDFetchEndpoints {
    listUrl: string;
    getUrl: string;
    createUrl: string;
    updateUrl: string;
    deleteUrl: string;
}

export function defaultCrudFetchInitializer(url: string): DefaultCrudSettingsConstructor {
    return {
        listUrl: url + '/list',
        getUrl: url,
        createUrl: url,
        updateUrl: url,
        deleteUrl: url,
    } as any;
}

@Injectable()
export class NewDefaultCrudFetchService<T extends { id }> extends CrudFecthService {
    listUrl: string;
    getUrl: string;
    createUrl: string;
    updateUrl: string;
    deleteUrl: string;

    constructor(
        init: DefaultCrudSettingsConstructor,
        protected http: HttpClient,
    ) {
        super();
        this.listUrl = init.listUrl;
        this.getUrl = init.getUrl;
        this.createUrl = init.createUrl;
        this.updateUrl = init.updateUrl;
        this.deleteUrl = init.deleteUrl;
    }

    getList(payload?: ListPayload) {
        return this.http.get<ListResponse<T>>(`${this.listUrl}?${serializeGet(payload)}`);
    }

    get(payload) {
        return this.http.get<ApiResponse<T>>(`${this.getUrl}/${payload.id}`)
            .pipe(map(it => it.data));
    }

    create(payload) {
        return this.http.post<ApiResponse<T>>(this.createUrl, payload);
    }

    update(payload) {
        return this.http.put<ApiResponse<T>>(`${this.updateUrl}/${payload.id}`, payload);
    }

    delete(payload) {
        return this.http.delete(`${this.deleteUrl}/${payload.id}`, payload);
    }

}

@Injectable()
export class NewDefaultCrudFetchServiceWithPatch<T extends { id }> extends NewDefaultCrudFetchService<T> {
    constructor(
        init: DefaultCrudSettingsConstructor,
        http: HttpClient,
    ) {
        super(init, http);
    }

    update(payload) {
        return this.http.patch<ApiResponse<T>>(`${this.updateUrl}/${payload.id}`, payload);
    }
}

@Injectable()
export class NewDefaultCrudFetchServiceWithPostUpdate<T extends { id }> extends NewDefaultCrudFetchService<T> {
    private readonly updateUrlEndpoint: (object: any) => string;
    constructor(
        init: DefaultCrudSettingsConstructor,
        http: HttpClient,
    ) {
        super(init, http);
        this.updateUrlEndpoint = init.updateUrlEndpoint;
    }

    update(payload) {
        const updateUrl = this.updateUrl ? `${this.updateUrl}/${payload.id}` : this.updateUrlEndpoint(payload);
        return this.http.post<ApiResponse<T>>(updateUrl, payload);
    }
}
