import { NgModule, Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'mathCeil',
})
export class MathCeilPipe implements PipeTransform {
    transform(number: number): number {
            return Math.ceil(number);
    }
}

@NgModule({
    declarations: [MathCeilPipe],
    exports: [MathCeilPipe],
})
export class MathCeilPipeModule {}
