<ng-template #defaultColumnHeading let-col="col">
    <span class="nowrap">{{ col.name }}</span>
</ng-template>

<table class="table table-striped mb-0">
    <thead>
    <tr>
        <ng-container *ngFor="let col of headerColumns">
            <ng-container *ngIf="(col.template || col.component); else defaultColumn">
                <th [data]="{ column: col, component: col.component }"
                    [template]="getRelatedTemplate$(col) | async"
                    [style.width]="!!col.width ? col.width : 'auto'"
                    config-viewer
                ></th>
            </ng-container>
            <ng-template #defaultColumn>
                <th
                        [style.width]="!!col.width ? col.width : 'auto'"
                >
                    <ng-container
                            *ngTemplateOutlet="columnHeading?.template || defaultColumnHeading; context: {col: col}"
                    ></ng-container>
                </th>
            </ng-template>
        </ng-container>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let row of rows; let i = index; trackBy: trackByIndex">
        <tr [style.background-color]="colorization.getRowColor(row, (rows[i + 1] ? rows[i + 1] : null ))">
            <td *ngFor="let col of columns; trackBy: trackByIndex" [data]="getData(col, row, i)"
                [template]="getRelatedTemplate$(col) | async"
                config-viewer
            ></td>
        </tr>
        <tr *ngIf="colorization.showInfo(row, (rows[i + 1] ? rows[i + 1] : null ))"
            [style.background-color]="colorization.getRowColor(row, (rows[i + 1] ? rows[i + 1] : null ))">
            <td [colSpan]="columns.length"
                [innerHTML]="colorization.info(row, (rows[i + 1] ? rows[i + 1] : null ))"></td>
        </tr>
    </ng-container>

    </tbody>
    <tfoot *ngIf="footer">
    <ng-template [ngTemplateOutlet]="footer.template"></ng-template>
    </tfoot>
</table>
