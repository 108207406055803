import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {detect} from 'detect-browser';

if (environment.production) {
  enableProdMode();
}

document.body.classList.add(detect().name);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
