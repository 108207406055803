import { Injectable } from '@angular/core';

@Injectable()
export abstract class IColorizationInterface {
    protected constructor() { }

    abstract getRowColor(row: any, nextRow: any): String;

    abstract showInfo(row: any, nextRow: any): boolean;

    abstract info(row: any, nextRow: any): String;
}

@Injectable()
export class ColorizationService extends IColorizationInterface {

    constructor() {
        super();
    }

    getRowColor(row: any, nextRow: any): String {
        return '';
    }

    info(row: any, nextRow: any): String {
        return '';
    }

    showInfo(row: any, nextRow: any): boolean {
        return false;
    }

}
