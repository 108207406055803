import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  photoLoaded = false;
  photoSrc = '';
  modalRef: BsModalRef;

  safe: (s: string) => SafeUrl;

  constructor(sanitizer: DomSanitizer) {
    this.safe = sanitizer.bypassSecurityTrustUrl;
  }
}
