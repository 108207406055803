import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RecursiveTemplates } from 'shared/base/recursive-templates.base';
import { ColumnConfig } from '../../table-config.model';
import { ControlCellComponent } from '../control-cell/control-cell.component';
import { ButtonClickedEvent, RowButton, SelectedRows, TableWithControlsService } from '../table-with-controls.service';

@Component({
  selector: 'app-table-with-controls',
  templateUrl: './table-with-controls.component.html',
})
export class TableWithControlsComponent extends RecursiveTemplates implements OnChanges, OnDestroy {
  get rows(): any[] {
    return this._rows;
  }

  @Input() set rows(value: any[]) {
    this.tableWithControlsService.setAllRows(false);
    this._rows = value;
  }

  private _rows: any[] = [];

  @Input() columns: ColumnConfig[] = [];
  @Input() selectable = true;
  @Input() noRowsMessage = 'Table is empty';
  columnsWithControls: ColumnConfig[];

  @Input() buttons: RowButton[];
  @Output() buttonClicked = new EventEmitter<ButtonClickedEvent>();
  @Output() selectionChanged = new EventEmitter<SelectedRows>();

  constructor(private tableWithControlsService: TableWithControlsService, public cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['columns']) {
      this.columnsWithControls = [{
        headerComponent: ControlCellComponent,
        component: ControlCellComponent,
      }].concat(this.columns as any);
    }

    if (changes['buttons']) {
      this.tableWithControlsService.setButtons(this.buttons);
    }

    this.tableWithControlsService.selectable = this.selectable;

    this.tableWithControlsService.buttonClicked$.subscribe((e) => this.buttonClicked.emit(e));
    this.tableWithControlsService.selectionChanged$.subscribe((e) => this.selectionChanged.emit(e));
  }

  ngOnDestroy() {
    this.tableWithControlsService.dispose();
  }

}
