import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toggleAnimation } from 'shared/animations/toggle.animation';
import { MenuItem } from 'shared/modules/navigation/sidebar-menu-item/menu-item.model';

@Component({
  selector: 'app-sidebar-menu-item',
  templateUrl: './sidebar-menu-item.component.html',
  animations: [toggleAnimation],
})
export class SidebarMenuItemComponent implements OnChanges {
  @Input() menuItem: MenuItem;
  hasOnlyLabel = false;
  hasChildren = false;
  once = true;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    this.hasOnlyLabel = ['route', 'icon', 'children'].reduce((a, b) => a && !this.menuItem.hasOwnProperty(b), true);
    this.hasChildren = !!(this.menuItem.children && this.menuItem.children.length);
  }

  openItem($event: MouseEvent) {
    if (this.hasChildren) {
      this.menuItem.isOpened = !this.menuItem.isOpened;
    }
    $event.stopPropagation();
  }

  isActive(route) {
    return (this.router.url).includes(route);
  }
}
