import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { WizardSteps } from 'shared/modules/modal/wizard-modal/wizard-modal.component';
import { ColumnConfig } from 'shared/modules/table/table-config.model';

export type FormConfigType = 'wizard' | 'common';

export interface IDefaultCrudSettingsConstructor<T = { view?: boolean, edit?: boolean }> {
    title?: string;
    createTitle?: string;
    updateTitle?: string;
    deleteTitle?: string;
    listUrl: string;
    getUrl?: string;
    createUrl?: string;
    updateUrl?: string;
    updateUrlEndpoint?: (object: any) => string;
    deleteUrl?: string;
    permissions: T;
    columns: ColumnConfig[];
    formType?: FormConfigType;
    formConfig: Observable<FormlyFieldConfig[] | WizardSteps[]>;
    toFormSerializer?: Function;
    fromFormSerializer?: Function;
}

@Injectable()
export class DefaultCrudSettingsConstructor implements IDefaultCrudSettingsConstructor {
    title?: string;
    createTitle?: string;
    updateTitle?: string;
    deleteTitle?: string;
    listUrl: string;
    getUrl?: string;
    createUrl?: string;
    updateUrl?: string;
    updateUrlEndpoint?: (object: any) => string;
    deleteUrl?: string;
    permissions: { view: boolean, edit?: boolean, control?: boolean };
    columns: ColumnConfig[];
    formType: FormConfigType = 'common';
    formConfig: Observable<FormlyFieldConfig[] | WizardSteps[]>;
    toFormSerializer?: Function;
    fromFormSerializer?: Function;
    reload?: Observable<any>;

    constructor(payload: IDefaultCrudSettingsConstructor) {
        Object.assign(this, payload);
    }
}
