import {DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, UP_ARROW} from '@angular/cdk/keycodes';
import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {EntityState, EntityStore, QueryEntity, StateHistoryPlugin, StoreConfig} from '@datorama/akita';
import {BindObservable} from 'bind-observable';
import deepcopy from 'deepcopy';
import {isEqual} from 'lodash-es';
import {ToastrService} from 'ngx-toastr';
import {mapShift} from 'portal/pages/main/place-booking/old-map/map/constants';
import {Floor} from 'portal/pages/main/place-booking/old-map/map/map-assets.service';
import {PointLocalStateService} from 'portal/pages/main/place-booking/old-map/map/points/points-local-state.service';
import {Zoom} from 'portal/pages/main/place-booking/old-map/map/zoom';
import {EnumsService} from 'portal/services/enums.service';
import {PermissionsService, permStub} from 'portal/services/permissions.service';
import {combineLatest, forkJoin, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, exhaustMap, filter, finalize, first, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ApiResponse} from 'shared/interfaces/response';
import {SelectOptions} from 'shared/modules/forms/options.interface';
import {UnpackObjectValues, UnpackObservable} from 'shared/types';
import {defaultSortByProp} from 'shared/utils/defaultSortByProp';
import {makeEnum} from 'shared/utils/make-enum';
import {selectOptionsToMap} from 'shared/utils/operators';
import {range} from 'shared/utils/range';
import {MouseCoordinates} from 'shared/utils/selection';
import {listToObject} from 'shared/utils/utils';
import {A, O} from 'ts-toolbelt';
import {DefaultFetchService} from 'shared/modules/table/chunks/default-crud-fetch.service';

export type PartialEmployee = { employee_id: number, employee_name: string };

export interface Coord {
  x: number;
  y: number;
}

export interface PointTransform {
  scale: number;
  rotate: number;
  flipX: boolean;
  flipY: boolean;
}

export interface PointBase extends PointTransform {
  floor: Floor;
  point: Coord;
  id: number;
  name: string;
}

export const PointTypes = makeEnum('employee', 'printer', 'title', 'booking', 'bookingConference');
export type PointTypes = Point['type'];

export interface EmployeePoint extends PointBase {
  type: 'employee';
  employee_id?: number;
}

export interface BookingPoint extends PointBase {
  type: 'booking';
  booking_place_type_id?: number;
}

export interface BookingConferencePoint extends PointBase {
  type: 'bookingConference';
  seats?: number;
  booking_place_type_id?: number;
}

export interface TitlePoint extends PointBase {
  type: 'title';
  title?: string;
  color?: string;
}

export interface PrinterPoint extends PointBase {
  type: 'printer';
}

type check = A.Equals<PointTypes, keyof typeof PointTypes>;
const check = (): 1 => ({} as check);

export type Point = EmployeePoint | TitlePoint | PrinterPoint | BookingPoint | BookingConferencePoint;
export type FullPointModel =
  A.Compute<Omit<PointBase & O.Optional<EmployeePoint & TitlePoint & PrinterPoint & BookingPoint & BookingConferencePoint>, 'type'> & {
    type: PointTypes
  }>;

export interface ExtendedEmployeePoint extends EmployeePoint {
  employee_name: string;
}

export type ExtendedPoint = ExtendedEmployeePoint | TitlePoint | PrinterPoint | BookingPoint | BookingConferencePoint;
export const pointToExtendedPoint = (p: Point, employeesMap: { [id: string]: string }): ExtendedPoint =>
  p.type === 'employee' ? {...p, employee_name: employeesMap[p.employee_id]} : p;

export interface PointBackend {
  id: number;
  name: string;
  x: number;
  y: number;
  scale: number;
  rotate: number;
  floor: Floor;
  type: string;
  employee_id?: number;
  booking_place_type_id?: number;
  comment?: string;
}

export const pointToBackend = (p: Point): PointBackend => {
  const baseFields = {
    id: p.id,
    name: p.name,
    floor: p.floor,
    rotate: p.rotate,
    scale: p.scale,
    x: p.point.x,
    y: p.point.y,
    type: `${+p.flipX} ${+p.flipY} ${p.type}`,
  };
  switch (p.type) {
    case 'employee':
      return {...baseFields, employee_id: p.employee_id};
    case 'title':
      return {...baseFields, comment: JSON.stringify({title: p.title, color: p.color})};
    case 'printer':
      return {...baseFields};
    case 'booking':
      return {...baseFields, booking_place_type_id: p.booking_place_type_id};
    case 'bookingConference':
      return {...baseFields, booking_place_type_id: 2, comment: JSON.stringify({seats: p.seats})};
  }
};
export const backendToPoint = (p: PointBackend): Point => {
  const chunks = p.type ? p.type.split(' ') : [];
  const [flipX, flipY, typePart] = chunks;
  let type = (typePart || PointTypes.employee) as PointTypes;
  if (type === 'booking') {
    if (p.booking_place_type_id === 2) {
      type = 'bookingConference';
    }
  }
  const additional = JSON.parse(p.comment);

  const baseFields = {
    floor: p.floor,
    point: {x: p.x, y: p.y},
    scale: p.scale,
    rotate: p.rotate,
    id: p.id,
    name: p.name,
    flipX: !!+flipX,
    flipY: !!+flipY,
  };
  switch (type) {
    case 'employee':
      return {...baseFields, type, employee_id: p.employee_id};
    case 'title':
      return {...baseFields, type, title: additional.title, color: additional.color};
    case 'printer':
      return {...baseFields, type};
    case 'booking':
      return {...baseFields, type, booking_place_type_id: p.booking_place_type_id};
    case 'bookingConference':
      return {...baseFields, type, booking_place_type_id: p.booking_place_type_id, seats: additional?.seats};
  }
};

export enum BookingPlaceTypesEnum {
  COWORKING = 1,
  CONFERENCE = 2
}

export enum BookingPlaceStatusEnum {
  VACANT = 1,
  OCCUPIED = 2
}

export const BookingPlaceTypes = [
  {label: 'COWORKING', value: BookingPlaceTypesEnum.COWORKING},
  {label: 'CONFERENCE', value: BookingPlaceTypesEnum.CONFERENCE},
];

export interface PointsState extends EntityState<Point> {}

export type WorkplaceInfoForEmployee = UnpackObjectValues<UnpackObservable<typeof PointsService.prototype['employeePointMap$']>>;

@Injectable({providedIn: 'root'})
export class PointsPersistentStore extends DefaultFetchService {
  static newPointIdBase = 10000;
  private initialPoints: Point[];

  constructor(http: HttpClient, private permissionsService: PermissionsService) {
    super({baseUrl: 'api/v2/office-map/object'}, http);
  }

  getPoints$(): Observable<Point[]> {
    const dontHaveAccess = !this.permissionsService.getPermissionValue(permStub.v2.OfficeMap.view);
    if (dontHaveAccess) {
      return of([]);
    }
    return this.http.get<ApiResponse<PointBackend[]>>('api/v2/office-map/object/list')
      .pipe(
        map(r => r.data),
        map(points => points.map(backendToPoint)),
        tap<Point[]>(points => this.initialPoints = points.map(p => deepcopy(p))),
      );
  }

  setPoints$(points: Point[]) {
    const newPoints = points.filter(p => p.id > PointsPersistentStore.newPointIdBase);

    const pointIds = new Set(points.map(it => it.id));
    const deletedPointIds = new Set(
      this.initialPoints
        .filter(it => !pointIds.has(it.id))
        .map(p => p.id),
    );
    const initialPointsMap = listToObject(this.initialPoints, p => p.id);
    const modifiedPoints = points
      .filter(p => p.id < PointsPersistentStore.newPointIdBase)
      .filter(p => !deletedPointIds.has(p.id))
      .filter(p => !isEqual(initialPointsMap[p.id], p));

    return forkJoin([
      ...newPoints.map(p => this.create(pointToBackend(p))),
      ...Array.from(deletedPointIds).map(id => this.delete({id})),
      ...modifiedPoints.map(p => this.update(pointToBackend(p))),
    ]);
  }
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'points', resettable: true})
export class PointsStore extends EntityStore<PointsState, Point> {
  initiated = false;

  constructor(private pointsPersistentStore: PointsPersistentStore) {
    super();
  }

  async initStoreWithPersistentData() {
    const points = await this.pointsPersistentStore.getPoints$().toPromise();
    this.reset();
    this.set(points);
    this.initiated = true;
  }
}

@Injectable({providedIn: 'root'})
export class PointsQuery extends QueryEntity<PointsState, Point> {
  constructor(protected store: PointsStore) {
    super(store);
  }
}

@Injectable({providedIn: 'root'})
export class PointsService implements OnDestroy {
  @BindObservable()
  search = '';
  private search$!: Observable<string>;
  searchString$ = this.search$.pipe(
    map(it => typeof it === 'string' ? it : ''),
    distinctUntilChanged(),
  );

  @BindObservable()
  selectedFloor: Floor = '6c';
  selectedFloor$!: Observable<Floor>;

  @BindObservable()
  editing = false;
  editing$!: Observable<boolean>;

  editingToggleLabel$ = this.editing$.pipe(map(editing => editing ? 'Завершить редактирование' : 'Редактировать'))
  editingToggleIcon$ = this.editing$.pipe(map(editing => editing ? 'fa fa-edit' : 'fas fa-map-marker'))

  @BindObservable()
  private showGuides = false;
  showGuides$!: Observable<boolean>;

  @BindObservable()
  private canBeSelectedByMouse = false;
  canBeSelectedByMouse$!: Observable<boolean>;

  private saving$ = new Subject<any>();

  employees$: Observable<SelectOptions> = this.enumsService.getEmployeesSimpleList().pipe(shareReplay(1));
  employeesMap$ = this.employees$.pipe(selectOptionsToMap(), shareReplay(1));

  private allPoints$ = this.pointsQuery.selectAll();

  private allPointsOnTheSelectedFloor$ = combineLatest([this.allPoints$, this.selectedFloor$]).pipe(
    map(([allPoints, selectedFloor]) => allPoints.filter(p => p.floor === selectedFloor)),
    shareReplay(1),
  );
  private allTheSelectedPointsOnTheSelectedFloor$ = combineLatest([
    this.allPointsOnTheSelectedFloor$,
    this.pointLocalState.selectedPointIds$,
  ]).pipe(
    map(([points, selectedIds]) => points.filter(p => selectedIds.has(p.id))),
    shareReplay(1),
  );
  private allUnselectedPointsOnTheSelectedFloor$ = combineLatest([
    this.allPointsOnTheSelectedFloor$,
    this.pointLocalState.selectedPointIds$,
  ]).pipe(
    map(([points, selectedIds]) => points.filter(p => !selectedIds.has(p.id))),
    shareReplay(1),
  );

  pointsForSearch$ = combineLatest([this.allPoints$, this.employeesMap$]).pipe(
    map(([points, employeesMap]): ExtendedPoint[] =>
      points.map(p => pointToExtendedPoint(p, employeesMap))),
    shareReplay(1),
  );

  points$ = combineLatest([
    this.searchString$,
    this.allPointsOnTheSelectedFloor$,
    this.pointsForSearch$,
  ]).pipe(
    map(([search, allPointsOnTheSelectedFloor]) =>
      allPointsOnTheSelectedFloor
        .map(point => ({point, pointSearchMatch: this.pointSearchMatch[point.id]})),
    ),
    shareReplay(1),
  );

  sortedPoints$ = combineLatest([
    this.points$,
    this.pointLocalState.selectedPointIds$,
    this.pointLocalState.highlightedPointId$,
  ]).pipe(
    map(([points, selectedPointIds, highlightedPointId]) => {
      const matchedPointIds = points.filter(it => it.pointSearchMatch).map(it => it.point.id);
      const pointsToBeUpper = new Set([...selectedPointIds, ...matchedPointIds, highlightedPointId]);
      return Array.from(points).sort(defaultSortByProp(p => pointsToBeUpper.has(p.point.id)));
    }),
    shareReplay(1),
  );

  guides$: Observable<{ x, y }[]> = combineLatest([
    this.showGuides$,
    this.allUnselectedPointsOnTheSelectedFloor$,
  ]).pipe(map(([showGuides, unselectedPoints]) => showGuides ? unselectedPoints.map(it => it.point) : []));

  pointsHistoryController: StateHistoryPlugin<FullPointModel, PointsState>;
  private uniqueId: number;
  private pointsWithEmployee$ = this.allPoints$.pipe(
    map(points => points.filter(p => p.type === 'employee' && !!p.employee_id)),
    shareReplay(1),
  );
  private employeePointMap$ = this.pointsWithEmployee$
    .pipe(
      map((points) => listToObject(
        points,
        p => p.type === 'employee' && p.employee_id,
      )),
      shareReplay(1),
    );

  private pointEmployeeMap$ = combineLatest([this.pointsQuery.selectAll(), this.employeesMap$])
    .pipe(
      map(([points, employeesMap]) => listToObject(
        points,
        ({id}) => id,
        p => pointToExtendedPoint(p, employeesMap),
      )),
      filter(it => Object.keys(it).length !== 0),
      shareReplay(1),
    );

  highlightedPointInfo$ = this.pointEmployeeMap$.pipe(
    switchMap(pointEmployeeMap => this.pointLocalState.highlightedPointId$
      .pipe(
        map(id => pointEmployeeMap[id]),
        shareReplay(1),
      ),
    ),
  );

  private sub = new Subscription();

  private pointSearchMatch: { [pointId: number]: boolean } = {};
  pointsSearchFn = (search: string, p: ExtendedPoint) => {
    search = search.toLowerCase();
    let res = (p.name && p.name.toLowerCase().indexOf(search) > -1);
    if (!res && p.type === 'employee') {
      res = res || p.employee_name && p.employee_name.toLowerCase().indexOf(search) > -1;
    } else if (!res && p.type === 'title') {
      res = res || p.title && p.title.toLowerCase().indexOf(search) > -1;
    }
    this.pointSearchMatch[p.id] = res;
    return res;
  }

  fullMathFn = (search: string, points: ExtendedPoint[]) => {
    search = search.toLowerCase();
    const fullMatchPoints = points.filter(p => {
      let res = (p.name && p.name.toLowerCase() === search);
      if (!res && p.type === 'employee') {
        res = res || p.employee_name && p.employee_name.toLowerCase() === search;
      } else if (!res && p.type === 'title') {
        res = res || p.title && p.title.toLowerCase() === search;
      }
      return res;
    });
    if (fullMatchPoints.length > 0) {
      const foundPoint = fullMatchPoints[0];
      this.pointSearchMatch = [];
      this.pointSearchMatch[foundPoint.id] = true;
    }
  }

  constructor(
    private pointsQuery: PointsQuery,
    private pointsStore: PointsStore,
    private pointsPersistentStore: PointsPersistentStore,
    private pointLocalState: PointLocalStateService,
    private zoomController: Zoom,
    private enumsService: EnumsService,
    private toastr: ToastrService,
  ) {
    this.sub.add(
      this.setUpSaving(),
    );
    this.sub.add(
      this.setUpSearch(),
    );
    this.sub.add(
      this.setUpMoveViaButtons(),
    );
    this.resetStoreAndDependants();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getNameAndIdForTheNewPoint() {
    return {
      id: ++this.uniqueId + PointsPersistentStore.newPointIdBase,
      name: `Точка № ${this.uniqueId}`,
    };
  }

  addPoint() {
    const nameAndIdForTheNewPoint = this.getNameAndIdForTheNewPoint();
    this.pointsStore.add({
      ...nameAndIdForTheNewPoint,
      floor: this.selectedFloor,
      rotate: 0,
      scale: 1,
      point: {x: .5, y: .5},
      flipX: false,
      flipY: false,
      type: 'employee',
    }, {prepend: true});
    this.pointLocalState.getLocalStateForPoint$(nameAndIdForTheNewPoint.id).highlighted.set(true);
  }

  updatePoint(updatedPoint: Point) {
    this.pointsStore.update(updatedPoint.id, updatedPoint);
    this.clearFutureHistory();
  }

  deletePoint(id) {
    this.pointsStore.remove(id);
    this.pointLocalState.remove(id);
    this.clearFutureHistory();
  }

  deleteSelectedPoints() {
    const pointIds = Array.from(this.pointLocalState.selectedPointIds());
    this.pointsStore.remove(pointIds);
    this.pointLocalState.remove(pointIds);
    this.clearFutureHistory();
  }

  updateSelectedPoints(p: Partial<Point>) {
    const pointIds = Array.from(this.pointLocalState.selectedPointIds());
    this.pointsStore.update(pointIds, p);
    this.clearFutureHistory();
  }

  toggleEditing() {
    if (this.editing) {
      this.showGuides = false;
    }
    this.editing = !this.editing;
    if (!this.editing) {
      this.canBeSelectedByMouse = false;
      this.pointLocalState.updateSelected({selected: false});
      this.clearFutureHistory();
    }
  }

  toggleGuides() {
    this.showGuides = !this.showGuides;
  }

  toggleCanBeSelectedByMouse() {
    this.canBeSelectedByMouse = !this.canBeSelectedByMouse;
  }

  private setUpSaving() {
    return this.saving$
      .pipe(
        exhaustMap(() => this.pointsPersistentStore.setPoints$(this.pointsQuery.getAll())
          .pipe(switchMap(() => this.resetStoreAndDependants()))),
      )
      .subscribe(() => this.toastr.success('Сохранение прошло успешно!'));
  }

  private setUpSearch() {
    return combineLatest([this.searchString$, this.pointsForSearch$])
      .pipe(
        tap(([search, points]) => points.forEach(p => this.pointsSearchFn(search, p))),
        tap(([search, points]) => this.fullMathFn(search, points)),
      ).subscribe();
  }

  private setUpMoveViaButtons() {
    return this.allTheSelectedPointsOnTheSelectedFloor$
      .pipe(switchMap(points => fromEvent<KeyboardEvent>(document, 'keydown').pipe(map(ev => ({ev, points})))))
      .subscribe(({ev, points}) => {
        let axis: keyof Coord;
        let shift = mapShift;
        switch (ev.keyCode) {
          case RIGHT_ARROW:
            axis = 'x';
            break;
          case LEFT_ARROW:
            axis = 'x';
            shift *= -1;
            break;
          case UP_ARROW:
            axis = 'y';
            shift *= -1;
            break;
          case DOWN_ARROW:
            axis = 'y';
            break;
          default:
            return;
        }
        const pointIds = points.map(it => it.id);
        this.pointsStore.update(pointIds, p => ({point: {...p.point, [axis]: p.point[axis] + shift}}));
      });

  }

  save() {
    this.saving$.next();
  }

  private clearFutureHistory() {
    this.pointsHistoryController.clear(history => ({
      past: history.past,
      present: history.present,
      future: [],
    }));
  }

  private async resetStoreAndDependants() {
    this.pointsHistoryController = await this.pointsQuery
      .selectAll()
      .pipe(
        first(),
        map(() => new StateHistoryPlugin(this.pointsQuery)),
      ).toPromise();
    this.uniqueId = await this.pointsQuery
      .selectAll()
      .pipe(
        first(),
        map(points => this.uniqueId = Math.max(...points.map(it => it.id), 0) + 1),
      ).toPromise();
    await this.pointsStore.initStoreWithPersistentData();
  }

  startMovingWithLeadingPoint$(leadingPointId: number) {
    const leadingPointController = this.pointLocalState.getLocalStateForPoint$(leadingPointId);

    return fromEvent(document, 'mousemove').pipe(
      switchMap(async (e: MouseEvent) => {
        const initialCoordinates = leadingPointController.coordinates.get();
        let relativeCoordinates = this.zoomController.getRelativeCoordinatesOfPointer(e);
        if (this.showGuides) {
          relativeCoordinates = await this.getNearestRelativeCoordinates(relativeCoordinates);
        }
        const dx = relativeCoordinates.x - initialCoordinates.x;
        const dy = relativeCoordinates.y - initialCoordinates.y;

        return this.pointLocalState.updateSelected(p => ({
          ...p,
          coordinates: {
            x: p.coordinates.x + dx,
            y: p.coordinates.y + dy,
          },
        }));
      }),
      takeUntil(fromEvent(document, 'mouseup')),
      finalize(() => {
        const stateToSave = listToObject(
          this.pointLocalState.getSelected(),
          p => p.id,
          p => p.coordinates,
        );
        this.pointsStore.update(p => !!stateToSave[p.id], p => ({point: stateToSave[p.id]}));
      }),
    );
  }

  private async getNearestRelativeCoordinates(relativeCoords: Coord, threshold = 1 / 100): Promise<{ x, y }> {
    const points = await this.allUnselectedPointsOnTheSelectedFloor$.pipe(first()).toPromise();
    const getNearestPointOnAxisIfLessThenThreshold = (coord: keyof Coord) => points
      .map(it => ({value: it.point[coord], difference: Math.abs(relativeCoords[coord] - it.point[coord])}))
      .sort(defaultSortByProp(it => it.difference))
      .slice(0, 1)
      .filter(it => it.difference < threshold)
      .map(it => it.value)
      .shift();

    return {
      x: getNearestPointOnAxisIfLessThenThreshold('x') || relativeCoords.x,
      y: getNearestPointOnAxisIfLessThenThreshold('y') || relativeCoords.y,
    };
  }

  async centerSelectedBy(axis: keyof Coord) {
    const points = await this.allTheSelectedPointsOnTheSelectedFloor$.pipe(first()).toPromise();
    const pointIds = points.map(it => it.id);
    const newCoord = Math.min(...points.map(it => it.point[axis]));
    this.pointsStore.update(pointIds, p => ({point: {...p.point, [axis]: newCoord}}));
    this.clearFutureHistory();
  }

  async setEquidistantSelectedBy(axis: keyof Coord) {
    const sortedPoints = (await this.allTheSelectedPointsOnTheSelectedFloor$.pipe(first()).toPromise())
      .sort(defaultSortByProp(it => it.point[axis]));
    const pointIds = sortedPoints.map(it => it.id);

    const initialValue = sortedPoints[0].point[axis];
    const lastValue = sortedPoints[sortedPoints.length - 1].point[axis];
    const step = (lastValue - initialValue) / (sortedPoints.length - 1);

    const newCoords = listToObject(
      range(sortedPoints.length),
      ix => sortedPoints[ix].id,
      ix => initialValue + step * ix,
    );
    this.pointsStore.update(pointIds, p => ({point: {...p.point, [axis]: newCoords[p.id]}}));
    this.clearFutureHistory();
  }

  async selectBetween(leftTop: MouseCoordinates, rightBottom: MouseCoordinates) {
    const [relativeLeftTop, relativeRightBottom] = [leftTop, rightBottom]
      .map(it => this.zoomController.getRelativeCoordinatesOfPointer(it));

    const pointIdsToBeSelected = (await this.allPointsOnTheSelectedFloor$.pipe(first()).toPromise())
      .filter(({point}) => point.x >= relativeLeftTop.x && point.x <= relativeRightBottom.x &&
        point.y >= relativeLeftTop.y && point.y <= relativeRightBottom.y)
      .map(p => p.id);

    this.pointLocalState.setSelectedOnly(pointIdsToBeSelected);
  }

  async copySelected() {
    const points = (await this.allTheSelectedPointsOnTheSelectedFloor$.pipe(first()).toPromise())
      .sort(defaultSortByProp(p => p.point.x))
      .sort(defaultSortByProp(p => p.point.y));
    const leftToppestPoint = points[0].point;

    this.toastr.info('Щелкните по месту на экране куда должны быть скопированы рабочие места');

    await new Promise(r => requestAnimationFrame(r));

    fromEvent<MouseEvent>(document, 'click').pipe(
      first(),
      map(e => {
        const relativeCoordinatesOfClick = this.zoomController.getRelativeCoordinatesOfPointer(e);
        const dx = relativeCoordinatesOfClick.x - leftToppestPoint.x;
        const dy = relativeCoordinatesOfClick.y - leftToppestPoint.y;
        return points
          .map(it => ({
            ...it,
            ...{employee_id: undefined},
            ...this.getNameAndIdForTheNewPoint(),
            point: {x: it.point.x + dx, y: it.point.y + dy},
          }));
      }),
    ).subscribe(newPoints => {
      this.pointsStore.add(newPoints, {prepend: true});
      requestAnimationFrame(() => this.pointLocalState.setSelectedOnly(newPoints.map(it => it.id)));
    });
  }

  getPointInfoForEmployee$(employee_id): Observable<WorkplaceInfoForEmployee> {
    return this.employeePointMap$.pipe(map(it => it[employee_id]), first());
  }
}
