<app-modal [title]="modalTitle" [modalRef]="modalRef">
    <div *ngIf="message" class="modal-body" [innerHtml]="message"></div>
    <div class="modal-footer" [ngClass]="{'border-top-0': !message}">
        <button class="btn btn-danger" (click)="answer.next(false)">
            {{ cancelButtonTitle }}
        </button>
        <button class="btn btn-success" (click)="answer.next(true)">
            {{ okButtonTitle }}
        </button>
    </div>
</app-modal>
