import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { FileWrapper, SingleFile } from 'portal/chunks/files/files.interfaces';
import { FilesV3Service } from 'portal/chunks/files/services/files-v3.service';
import { from, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { forkJoinOrEmpty } from 'shared/utils/operators';
import { listToObject } from 'shared/utils/utils';

export interface FileObject {
    content: string;
    name: string;
    size: string;
    type: string;
}

export const isFile = (it: any): it is File => it instanceof File;
export const isFileWrapper = (it: any): it is FileWrapper => it instanceof FileWrapper;

export function readFile({ file, fileName }): Promise<FileObject> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (onload: any) => {
            const fileObject: any = {};
            fileObject.content = onload.target.result.replace(/^.*;base64,/, '');
            fileObject.name = fileName;
            fileObject.size = file.size;
            fileObject.type = file.type;
            resolve(fileObject);
        };
        reader.onerror = (err) => {
            reject(err);
        };
        console.log(file);
        reader.readAsDataURL(file);
    });
}

export type FilesStorage = { [name: string]: File | FileWrapper };
export type FilesUploader = (files: File[]) => Observable<string[]>;

const defaultErrCb = (e, file) => {
    throw e.status === 413 ? `Файл ${file.name} слишком большой` : e;
};


export class FilesHandler {
    @BindObservable()
    public files: FilesStorage = {};
    private files$: Observable<FilesStorage>;
    // @ts-ignore
    filesArray$ = this.files$.pipe(map(f => Object.values(f)));


    constructor(private http: HttpClient, private filesUploader: FilesUploader) {
    }

    addFilesFromServer(files: SingleFile[]) {
        this.files = listToObject(
            files,
            file => file.guid, // this filed is used as last modified
            file => new FileWrapper(file),
        );
    }

    addFilesFromClient(files: FileList) {
         for (let i = 0; i < files.length; i++) {
             const file = files.item(i);
             this.files[file.lastModified] = file;
         }
         this.files = { ...this.files };
    }

    addSingleFileFromClient(file: File) {
        this.files[file.lastModified] = file;
        this.files = { ...this.files };
    }

    removeFile(file: File | FileWrapper) {
        delete this.files[file.lastModified];
        this.files = { ...this.files };
    }

    uploadFiles$(): Observable<string[]> {
        return this.filesArray$.pipe(
            // divide files on uploaded and not uploaded
            map(files => {
                const filesToUpload: File[] = [];
                const fileWrappers: FileWrapper[] = [];
                files.forEach(f => isFileWrapper(f)
                    ? fileWrappers.push(f)
                    : filesToUpload.push(f),
                );
                return { filesToUpload, fileWrappers };
            }),
            // process not uploaded ones via FileReader
            switchMap(({ filesToUpload, fileWrappers }) => {

                const uploadedFileGuids$ = this.filesUploader(filesToUpload);
                const alreadyUploadedFileGuids$ = of(fileWrappers.map(it => it.guid));

                return forkJoinOrEmpty([alreadyUploadedFileGuids$, uploadedFileGuids$])
                    .pipe(
                        map(([a, b]) => [...a, ...b]),
                        first(),
                    );
            }),
        );
    }
}

@Injectable({ providedIn: 'root' })
export class FilesHandlerFactory {
    constructor(private http: HttpClient, private filesV3Service: FilesV3Service) {}

    create() {
        return new FilesHandler(this.http, this.defaultFilesUploader());
    }

    createV3() {
        return new FilesHandler(this.http, this.v3FilesUploader());
    }

    defaultFilesUploader(): FilesUploader {
        return files => {
            const getFileUploadQuery$ = (file: FileObject) => {
                console.log(file);
                return this.http
                    .post<{ data: { guid: string } }>('/api/documents/upload_document_file', { file })
                    .pipe(
                        map(it => it.data.guid),
                        catchError(err => defaultErrCb(err, file)),
                    );
            };

            const fileToGuid$ = (file: File) => from(readFile({ file, fileName: file.name }))
                .pipe(switchMap(getFileUploadQuery$));

            return forkJoinOrEmpty(files.map(fileToGuid$));
        };
    }

    v3FilesUploader(): FilesUploader {
        return files => {
            const getFileUploadQuery$ = (file: File) => {
                return this.filesV3Service.upload(file).pipe(map(it => it.guid));
            };

            const fileToGuid$ = (file: File) => of(file)
                .pipe(switchMap(getFileUploadQuery$));

            return forkJoinOrEmpty(files.map(fileToGuid$));
        };
    }
}
