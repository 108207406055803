import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventDispatcherModule } from 'shared/modules/forms/event-dispatcher.directive';
import { MonthPickerComponent } from './month-picker.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, OwlDateTimeModule, EventDispatcherModule],
  declarations: [MonthPickerComponent],
  exports: [MonthPickerComponent],
})
export class MonthPickerModule {
}
