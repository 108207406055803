import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[swipe]'
})
export class SwipeDirective {
    @Output() swipeUp = new EventEmitter<void>();
    @Output() swipeDown = new EventEmitter<void>();
    @Output() swipeLeft = new EventEmitter<void>();
    @Output() swipeRight = new EventEmitter<void>();

    private startX: number = 0;
    private startY: number = 0;

    constructor(private el: ElementRef) {}

    @HostListener('touchstart', ['$event'])
    onTouchStart(event: TouchEvent) {
        this.startX = event.touches[0].clientX;
        this.startY = event.touches[0].clientY;
    }

    @HostListener('touchend', ['$event'])
    onTouchEnd(event: TouchEvent) {
        const endX = event.changedTouches[0].clientX;
        const endY = event.changedTouches[0].clientY;

        const diffX = endX - this.startX;
        const diffY = endY - this.startY;

        const absDiffX = Math.abs(diffX);
        const absDiffY = Math.abs(diffY);

        const hasSwipeUp = this.swipeUp.observers.length > 0;
        const hasSwipeDown = this.swipeDown.observers.length > 0;
        const hasSwipeLeft = this.swipeLeft.observers.length > 0;
        const hasSwipeRight = this.swipeRight.observers.length > 0;

        if (absDiffX > absDiffY) {
            // Horizontal swipe
            if (diffX > 0 && hasSwipeRight) {
                this.swipeRight.emit();
            } else if (diffX < 0 && hasSwipeLeft) {
                this.swipeLeft.emit();
            }
        } else {
            // Vertical swipe
            if (diffY > 0 && hasSwipeDown) {
                this.swipeDown.emit();
            } else if (diffY < 0 && hasSwipeUp) {
                this.swipeUp.emit();
            }
        }
    }
}
