import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ActionModalButton } from 'shared/modules/modal/modal.service';

@Component({
    selector: 'app-actions-modal',
    templateUrl: './actions-modal.component.html',
})
export class ActionsModalComponent<TModel, TResult> {
    message: string;
    modalRef: BsModalRef;
    model: any;
    actions: ActionModalButton<TModel, TResult>[];
    cancelButtonTitle: string;
    modalTitle: string;
    submit = new Subject<any>();

    constructor() {
    }

}
