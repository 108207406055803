import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'shared/utils/utils';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private static errorsToSkip = 0;

    static skipError(quantity: number = 1) {
        ErrorInterceptor.errorsToSkip += quantity;
    }

    static eraseCounter() {
        ErrorInterceptor.errorsToSkip = 0;
    }

    constructor(private toastr: ToastrService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const skipError = !!ErrorInterceptor.errorsToSkip;
        return next.handle(request).pipe(
            map((x) => {
                    if (x.type === HttpEventType.Response && request.responseType === 'json') {
                        if (isNullOrUndefined(x.body) || x.body.status === 'success') {
                            return x;
                        } else {
                            if (!skipError) {
                                this.toastr.error(x.body.error_message);
                            }
                            throw new HttpErrorResponse({
                                error: x.body,
                                headers: x.headers,
                                status: x.status,
                                statusText: x.statusText,
                            });
                        }
                    }
                    return x;
                },
            ),
            catchError((err: HttpErrorResponse) => {
                if (skipError) {
                    throw err;
                }
                let errorMessage = '';

                if (err.error instanceof ArrayBuffer) {
                    const decoder = new TextDecoder('utf-8');
                    const parsedError = JSON.parse(decoder.decode(err.error));
                    errorMessage = parsedError['error_message'];
                } else {
                    errorMessage = err.error['error_message'];
                }

                switch (err.status) {
                    case 503:
                        this.toastr.error('Сервис временно недоступен. Повторите попытку позднее');
                        break;
                    default:
                        this.toastr.error(['Ошибка сервера', errorMessage].join('. '));
                        break;
                }
                return throwError(err);
            }),
            finalize(() => skipError ? ErrorInterceptor.errorsToSkip-- : null),
        );
    }
}
