import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class PromptUpdateService {

    constructor() {
        // updates.available.subscribe(event => {
        //     toastr.info('Доступна новая версия. Обновите страницу.', '', {disableTimeOut: true});
        // });
    }
}
