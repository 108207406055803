import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ApiResponse } from 'shared/interfaces/response';
import { FileInfo, FileLink } from './download.interfaces';

export const FILE_STORAGE_API_PREFIX = new InjectionToken<string>('FILE_PREFIX');

@Injectable({ providedIn: 'root' })
export class DownloadService {
  constructor(private http: HttpClient, @Inject(FILE_STORAGE_API_PREFIX) private prefix: string) {}

  getFileInfo(guid: string) {
    return this.http.get<ApiResponse<FileInfo>>(`${this.prefix}/info/${guid}`);
  }

  getFileLink(guid: string) {
    return this.http.get<ApiResponse<FileLink>>(`${this.prefix}/link/${guid}`);
  }

  getFileContent(guid: string) {
    return this.http.get(`${this.prefix}/content/${guid}`, { responseType: 'blob' });
  }

  uploadImage(image: File) {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post<ApiResponse<FileInfo>>(`${this.prefix}/upload/image`, formData);
  }
}
