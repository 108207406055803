import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { TextMaskModule } from 'angular2-text-mask';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AgencyHistoryInputComponent } from 'shared/modules/forms/agency-history/agency-history-input.component';
import { EventDispatcherModule } from 'shared/modules/forms/event-dispatcher.directive';
import { FourStateSwitchModule } from 'shared/modules/forms/four-state-switch/four-state-switch.module';
import { HistoryInputComponent } from 'shared/modules/forms/history-input/history-input.component';
import { LabelWrapperModule } from 'shared/modules/forms/label-wrapper/label-wrapper.module';
import { Monthpicker, MonthpickerFormlyModule } from 'shared/modules/forms/month-picker/month-picker-formly-wrapper';
import { ObserveInputComponent } from 'shared/modules/forms/observe-input/observe-input.component';
import { RepeatTypeComponent } from 'shared/modules/forms/repeat-section-type/repeat-section-type';
import { SwitchModule } from 'shared/modules/forms/switch/switch.module';
import { TextEditorComponent } from 'shared/modules/forms/text-editor/text-editor.component';
import { TextEditorModule } from 'shared/modules/forms/text-editor/text-editor.module';
import { ThreeStateSwitchModule } from 'shared/modules/forms/three-state-switch/three-state-switch.module';
import { FormlyTypeaheadComponent } from 'shared/modules/forms/typeahead-select/formly-typeahead.component';
import { TypeaheadSelectComponent } from 'shared/modules/forms/typeahead-select/typeahead-select.component';
import { DateField } from './date/date';
import { DateRangepickerComponent } from './daterangepicker/date-rangepicker.component';
import { FormlyWrapperFormField } from './form-field-wrapper/form-field.wrapper';
import { LabelWrapperComponent } from './label-wrapper/label-wrapper.component';
import { FormlyFieldMaskedInput } from './masked-input/masked-input';
import { MonthDayField } from './month-day/month-day';
import { Multiselect } from './multiselect/multiselect';
import { Timepicker } from './timepicker/timepicker';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        FormlyBootstrapModule,
        NgSelectModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        SwitchModule,
        MonthpickerFormlyModule,
        LabelWrapperModule,
        ThreeStateSwitchModule,
        FourStateSwitchModule,
        TextEditorModule,

        FormlyModule.forRoot({
            types: [
                {
                    name: 'daterange',
                    component: DateRangepickerComponent,
                    wrappers: ['form-field'],
                    defaultOptions: {
                        templateOptions:
                            {
                                placement: 'bottom',
                            },
                    },
                },
                {
                    name: 'date',
                    component: DateField,
                    wrappers: ['form-field'],
                    defaultOptions: {
                        templateOptions:
                            {
                                pickerType: 'calendar',
                            },
                    },
                },
                {
                    name: 'month-day',
                    component: MonthDayField,
                    wrappers: ['form-field'],
                },
                {
                    name: 'multiselect',
                    component: Multiselect,
                    wrappers: ['form-field'],
                },
                {
                    name: 'timepicker',
                    component: Timepicker,
                    wrappers: ['form-field'],
                },
                {
                    name: 'monthpicker',
                    component: Monthpicker,
                    wrappers: ['form-field'],
                },
                {
                    name: 'masked-input',
                    component: FormlyFieldMaskedInput,
                    wrappers: ['form-field'],
                },
                {
                    name: 'history-input',
                    component: HistoryInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'agency-history',
                    component: AgencyHistoryInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'observe-input',
                    component: ObserveInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'repeat',
                    component: RepeatTypeComponent,
                },
                {
                    name: 'typeahead',
                    component: FormlyTypeaheadComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'app-text-editor',
                    component: TextEditorComponent,
                },
            ],
            wrappers: [
                { name: 'form-field', component: FormlyWrapperFormField },
            ],
        }),
        EventDispatcherModule,
        TooltipModule,
    ],
    exports: [
        FormlyModule,
        FormlyBootstrapModule,
        ReactiveFormsModule,
        FormsModule,
        LabelWrapperComponent,
        EventDispatcherModule,
        SwitchModule,
        TypeaheadSelectComponent,
        ThreeStateSwitchModule,
        FourStateSwitchModule,
        TextEditorModule,
    ],
    declarations: [
        FormlyWrapperFormField,
        DateRangepickerComponent,
        Multiselect,
        Timepicker,
        DateField,
        FormlyFieldMaskedInput,
        MonthDayField,
        RepeatTypeComponent,
        HistoryInputComponent,
        ObserveInputComponent,
        TypeaheadSelectComponent,
        AgencyHistoryInputComponent,
        FormlyTypeaheadComponent,
    ],
})
export class FormPartsModule {
}
