import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { DrawerComponent } from 'shared/modules/navigation/drawer/drawer.component';
import { MenuItem } from 'shared/modules/navigation/sidebar-menu-item/menu-item.model';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  exportAs: 'sidebar',
})
export class SidebarComponent implements OnDestroy {
  menuItems: MenuItem[];
  @ViewChild(DrawerComponent, { static: true }) drawerCmp: DrawerComponent;

  private destroyed$ = new Subject<any>();

  constructor(private nav: NavigationService, private router: Router) {
    this.menuItems = this.nav.getPermittedMenuItems();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  closeOnNavigation() {
    this.router.events.pipe(
      filter(x => x instanceof NavigationEnd),
      switchMap(() => this.drawerCmp.shouldBeOpened$.pipe(first())),
      filter(shouldBeOpened => shouldBeOpened === false),
      takeUntil(this.destroyed$),
    ).subscribe(() => this.drawerCmp.drawer.close());
  }
}
