import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalService } from 'shared/modules/modal/modal.service';
import { toPHPDate } from 'shared/utils/form.utils';
import { Utils } from 'shared/utils/utils';
import { DashboardService } from 'portal/pages/dashboard/dashboard.service';
import { map, switchMap } from 'rxjs/operators';
import { maybeShowEmployeeHeadOnTopOfTheForm } from 'portal/pages/hr/vacation-application/vacation.applcation';
import { Observable } from 'rxjs';

@Injectable()
export class DismissialApplicationModal implements CanActivate {
  private form$: Observable<FormlyFieldConfig[]> = this.dashboardService.userInfo$
    .pipe(map((userInfo) => maybeShowEmployeeHeadOnTopOfTheForm([
      {
        key: 'dismissal_date',
        type: 'date',
        templateOptions: {
          label: 'Дата увольнения',
          minDate: Utils.fromToday(1),
          required: true,
        },
      },
      {
        key: 'comment',
        type: 'textarea',
        templateOptions: {
          label: 'Комментарий',
          rows: 3,
        },
      },
    ], userInfo)));

  constructor(
    private router: Router,
    private modal: ModalService,
    private http: HttpClient,
    private dashboardService: DashboardService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (window.location.pathname === '/dismissal') {
      this.router.navigateByUrl('/');
    }
    this.form$.pipe(
      switchMap(form => this.modal.createForm({
        title: 'Заявка на увольнение',
        buttonTitle: 'Отправить',
        form,
        onSubmit: (val: any) => this.http.post('/api/applications/dismissal/send_application', {
          ...val,
          ...{ dismissal_date: toPHPDate(val.dismissal_date) },
        }),
      })),
    ).subscribe();

    return false;
  }
}
