/* tslint:disable */
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'formly-wrapper-form-field',
  template: `
      <app-label-wrapper [label]="to.hideLabel ? null : to.labelStream ? (labelStream | async) : label"
                         [formGroupClass]="'mb-3 ' + to.formGroupClass + (showError ? ' has-error' : '')" [alwaysOpened]="to.alwaysOpened">
          <ng-template #fieldComponent></ng-template>
          <div class="lw-inside-form-group">
              <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
                  <formly-validation-message [field]="field"></formly-validation-message>
              </div>
              <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
          </div>
      </app-label-wrapper>
  `,
})
export class FormlyWrapperFormField extends FieldWrapper {
  to: any;
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;

  get markerRequierd() {
    return this.to.required && this.to.hideRequiredMarker !== true;
  }

  get label() {
    return `${this.to.label}${this.markerRequierd ? ' *' : ''}`;
  }

  get labelStream() {
    return this.to.labelStream.pipe(map(it => this.markerRequierd ? `${it}*` : it));
  }

}
