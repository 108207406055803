import {NgModule} from '@angular/core';

import {SwipeDirective} from './swipe.directive';

@NgModule({
    imports: [],
    exports: [SwipeDirective],
    declarations: [SwipeDirective],
    providers: [],
})
export class SwipeDirectiveModule {
}
