import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { NavbarContentService } from "shared/modules/navigation/navbar-content.service";

import { NavbarComponent } from "./navbar.component";
import { UserPhotoModule } from "../../../../projects/portal/src/app/modules/user-photo/user-photo.module";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { NavbarItemDirective, NavbarItemWrapper } from "./navbar-item.directive"
import { TooltipModule } from "ngx-bootstrap/tooltip";
import {SwipeDirectiveModule} from "shared/directives/swipe/swipe-directive.module";

@NgModule({
    imports: [CommonModule, UserPhotoModule, RouterModule, TooltipModule, BsDropdownModule, CollapseModule, SwipeDirectiveModule],
    declarations: [NavbarComponent, NavbarItemDirective, NavbarItemWrapper],
    exports: [NavbarComponent, NavbarItemDirective]
})
export class NavbarModule {
    static forRoot(): ModuleWithProviders<NavbarModule> {
        return {
            ngModule: NavbarModule,
            providers: [NavbarContentService]
        };
    }
}
