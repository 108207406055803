import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserOptions } from '@sentry/browser';
import { RELEASE, SENTRY_SETTINGS, SentryErrorHandler } from './sentry-error-handler.service';

@NgModule({})
export class SentryErrorHandlerModule {
  static withSettings(settings: BrowserOptions, release: string): ModuleWithProviders<SentryErrorHandlerModule> {
    return {
      ngModule: SentryErrorHandlerModule,
      providers: [
        SentryErrorHandler,
        { provide: SENTRY_SETTINGS, useValue: settings },
        { provide: RELEASE, useValue: release },
        { provide: ErrorHandler, useExisting: SentryErrorHandler },
      ],
    };
  }
}
