import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ApiResponse } from 'shared/interfaces/response';
import { format } from 'date-fns';
import { laravelErrorsToInnerHTML } from 'shared/utils/operators';

export interface NewItemModel {
    effective_since?: string;
    field_value?: string;
    field_label?: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'history-input',
    template: `
        <div class="input-group">
            <input class="form-control"
                   [class.is-invalid]="showError"
                   [formlyAttributes]="field"
                   [value]="visibleValue | async"
            >
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" (click)="simpleVisible = !simpleVisible;"><i class="fa fa-edit"></i>
                </button>
            </div>
        </div>
        <div [hidden]="!simpleVisible" class="card mt-1 mb-1 position-relative" style="min-height: 150px">
            <div class="loader centered animated {{ (isLoading$ | async) ? 'my-show' : 'my-hide' }}"></div>
            <div class="card-body">
                <table class="table table-striped mb-0">
                    <thead>
                    <tr>
                        <th>
                            Значение
                        </th>
                        <th>
                            Актуально с
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of items; index as i">
                        <td>
                            {{ item.field_label }}
                        </td>
                        <td>
                            {{ item.effective_since | date:'shortDate' }}
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger" (click)="deleteItem(i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <div class="card-footer">
                <form [formGroup]="formNew" (ngSubmit)="submit.next()">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 ps-0">
                                <formly-form
                                        [model]="newModel"
                                        [fields]="fields"
                                        [form]="formNew">
                                </formly-form>
                            </div>
                            <div class="col-4 pe-0">
                                <button type="button" class="btn btn-secondary w-100 mb-3" (click)="add.next(newModel)">
                                    Добавить
                                </button>
                                <button type="submit" class="btn btn-primary w-100"
                                        [disabled]="!formNew.valid">Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    `,
})
export class HistoryInputComponent extends FieldType implements OnInit {

    visibleValue = new BehaviorSubject<string>('');
    isVisible = new BehaviorSubject<boolean>(false);
    simpleVisible = false;
    isLoading$ = new BehaviorSubject(true);
    items: NewItemModel[];
    newOptions: any[];
    newModel: NewItemModel = { effective_since: '', field_value: '', field_label: '' };
    formNew: FormGroup | FormArray = new FormGroup({});
    fields: FormlyFieldConfig[] = [
        {
            key: 'field_value',
            type: 'select',
            templateOptions: {
                label: 'Новое значение',
                options: [],
            },
        },
        {
            key: 'effective_since',
            type: 'date',
            templateOptions: {
                label: 'Актуально с',
                pickerMode: 'calendar',
            },
        },
    ];
    submit = new Subject<any>();
    add = new Subject<any>();

    constructor(private http: HttpClient, private toastr: ToastrService) {
        super();
    }


    ngOnInit(): void {
        this.visibleValue.next(this.model[this.field.templateOptions.visibleValue]);
        this.getEnums();
        this.getHistoryItems();
        this.submit.subscribe(() => this.saveItems());
        this.add.subscribe((model: NewItemModel) => this.addItem(model));
    }

    getHistoryItems() {
        const httpOptions = {
            headers: new HttpHeaders({
                'no-intercept': '1',
            }),
        };
        // tslint:disable-next-line:max-line-length
        this.http.get<ApiResponse<NewItemModel[]>>(
                this.field.templateOptions.endpointUrl(this.model),
                httpOptions,
            )
            .pipe(
                map(x => x.data.map(y => y)),
                finalize(() => this.isLoading$.next(false)),
            ).subscribe(items => this.items = items);
    }

    getEnums() {
        this.http.get(this.field.templateOptions.endpointListUrl(this.key)).pipe(
            map((x: any) => x.data.items.map(y => ({ value: y.field_value, label: y.field_label }))),
        ).subscribe(options => {
            this.fields[0].templateOptions.options = options;
            this.newOptions = options;
        });
    }

    addItem(model: NewItemModel) {
        if (model.effective_since) {
            model.effective_since = format(model.effective_since, 'YYYY-MM-DD');
        }
        model.field_label = this.newOptions.filter(item => item.value === model.field_value)[0].label;
        this.items.push(model);
    }

    deleteItem(index: number) {
        this.items.splice(index, 1);
    }

    saveItems() {
        this.http
            .put(this.field.templateOptions.endpointPutUrl(this.model, this.key), { items: this.items })
            .pipe(
                laravelErrorsToInnerHTML()
            )
            .subscribe(
                () => {
                    this.toastr.success('История сохранена.');
                },
                (error) => {
                    this.toastr.error(error);
                }
            );
    }
}
