import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, UpdateStateCallback } from '@datorama/akita';
import { Coord } from 'portal/pages/main/place-booking/old-map/map/points/points.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Omit } from 'shared/types';
import { distinctUntilArrayChanged } from 'shared/utils/operators';

interface LocalPoint {
  id: number;
  highlighted: boolean;
  selected: boolean;
  coordinates: Coord;
}

export interface LocalPointState extends EntityState<LocalPoint> {}

const initialPointLocalState: Omit<LocalPoint, 'id'> = {
  highlighted: false,
  selected: false,
  coordinates: { x: 0, y: 0 },
};

export type PointController = ReturnType<typeof PointLocalStateService.prototype.getLocalStateForPoint$>;

@Injectable({ providedIn: 'root' })
export class PointLocalStateService {
  private store = new EntityStore<LocalPointState, LocalPoint>([], { name: 'Local Points State' });
  private query = new QueryEntity<LocalPointState, LocalPoint, number>(this.store);

  selectedPointIds$: Observable<Set<number>> = this.query
    .selectAll({ filterBy: p => p.selected })
    .pipe(
      map(points => points.map(p => p.id)),
      distinctUntilArrayChanged(),
      map(ids => new Set(ids)),
    );
  highlightedPointId$: Observable<number> = this.query
    .selectAll({ filterBy: p => p.highlighted })
    .pipe(
      map(points => points.map(p => p.id).pop()),
      distinctUntilChanged(),
      map(ids => ids),
    );

  constructor() {}

  getLocalStateForPoint$(id: number) {
    if (!this.query.hasEntity(id)) {
      this.store.add({ ...initialPointLocalState, id });
    }

    return {
      highlighted: {
        set: (highlighted: boolean) => this.store.update(id, { highlighted }),
        $: this.query.selectEntity(id, p => p.highlighted),
      },
      selected: {
        set: (selected: boolean) => this.store.update(id, { selected }),
        get: () => this.query.getEntity(id).selected,
        toggle: () => this.store.update(id, { selected: !this.query.getEntity(id).selected }),
        $: this.query.selectEntity(id, p => p.selected),
      },
      coordinates: {
        set: (coordinates: Coord) => this.store.update(id, { coordinates }),
        get: () => this.query.getEntity(id).coordinates,
        $: this.query.selectEntity(id, p => p.coordinates).pipe(filter(it => !!it)),
      },
    };
  }

  selectedPointIds(): Set<number> {
    return new Set(this.getSelected().map(it => it.id));
  }

  updateSelected(p: Partial<LocalPoint> | UpdateStateCallback<LocalPoint>) {
    this.store.update(p => p.selected, p as any);
  }

  getSelected() {
    return this.query.getAll({ filterBy: p => p.selected });
  }

  setSelectedOnly(ids: number[]) {
    const idsSet = new Set(ids);
    this.store.update(() => true, p => ({ selected: idsSet.has(p.id) }));
  }

  remove(ids: number[] | number) {
    this.store.remove(ids);
  }
}
