import { Injectable } from '@angular/core';
import { EmployeeModalComponent } from 'portal/modules/employee-modal/employee-modal/employee-modal.component';
import { EmployeeApiModel } from 'portal/pages/main/employees/employees.interfaces';
import { EmployeesFetchService } from 'portal/pages/main/employees/employees.service';
import { PointsStore } from 'portal/pages/main/place-booking/old-map/map/points/points.service';
import { Observable } from 'rxjs';
import { ModalService } from 'shared/modules/modal/modal.service';

@Injectable()
export class EmployeeModalService {
  constructor(
    private modalService: ModalService,
    private employeesFetchService: EmployeesFetchService,
    private pointsStore: PointsStore,
  ) {
  }

  async openModalForEmployee(employee: EmployeeApiModel | number): Promise<Observable<void>> {

    if (!this.pointsStore.initiated) {
      await this.pointsStore.initStoreWithPersistentData();
    }

    let employeeApiModel: EmployeeApiModel;
    if (typeof employee === 'number') {
      employeeApiModel = await this.employeesFetchService.get({ id: employee }).toPromise();
    } else {
      employeeApiModel = employee;
    }

    const ref = this.modalService.bsModalService.show(EmployeeModalComponent);
    const cmp = ref.content;
    cmp.employee = employeeApiModel;
    return cmp.destroyed$;
  }
}
