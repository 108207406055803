import { Component, HostBinding, NgModule, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { EventDispatcherModule } from 'shared/modules/forms/event-dispatcher.directive';
import { MonthPickerModule } from 'shared/modules/forms/month-picker/month-picker.module';

/*tslint:disable component-class-suffix component-selector*/
@Component({
  selector: 'field-monthpicker',
  template: `
      <app-month-picker
              class="border-0 flex-grow-1"
              [formControl]="formControl"
              [dateFormat]="dateFormat"
      ></app-month-picker>
  `,
  styles: [`
      :host ::ng-deep .trigger {
          text-align: left;
          width: 100%;
      }
      :host ::ng-deep .change-month-button {
          display: none;
      }
  `],
})
export class Monthpicker extends FieldType implements OnInit {
  @HostBinding('class.form-control')
  @HostBinding('class.d-flex')
  thisIs = true;

  to: any;
  dateFormat: string;

  ngOnInit(): void {
    this.dateFormat = this.to.dateFormat || 'LLL yyyy';
  }
}


@NgModule({
  declarations: [Monthpicker],
  imports: [EventDispatcherModule, MonthPickerModule, FormsModule, ReactiveFormsModule],
  exports: [Monthpicker],
})
export class MonthpickerFormlyModule {}
