import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {defaultCrudFetchInitializer, NewDefaultCrudFetchService} from 'portal/chunks/default-crud/new-default-crud-fetch.service';
import {UserInfo} from 'portal/pages/dashboard/dashboard.service';
import {EmployeeApiModel, EmployeeFormModel} from 'portal/pages/main/employees/employees.interfaces';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {ApiResponse} from 'shared/interfaces/response';
import {laravelErrorsToInnerHTML} from 'shared/utils/operators';
import {DownloadService} from 'shared/modules/download/download.service';
import {FileInfo} from 'shared/modules/download/download.interfaces';

@Injectable({providedIn: 'root'})
export class EmployeesFetchService extends NewDefaultCrudFetchService<EmployeeApiModel> {
  constructor(http: HttpClient, private downloadService: DownloadService) {
    super(
      defaultCrudFetchInitializer('/api/v2/company/employee'),
      http,
    );
  }

  getSelf(): Observable<EmployeeApiModel> {
    return this.http.get<ApiResponse<UserInfo>>('/api/v2/user/access').pipe(map(res => res.data.employee));
  }

  getPhoto(photo_file_guid: string): Observable<Blob | undefined> {
    return photo_file_guid
      ? this.downloadService.getFileContent(photo_file_guid)
        .pipe(catchError(() => of(undefined)))
      : of(undefined);
  }

  private uploadPhoto(file: Blob, fileName: string): Observable<FileInfo> {
    const data = new FormData();
    const photo = new File([file], fileName);
    data.append('photo', photo);
    return this.http.post<ApiResponse<FileInfo>>('/api/v2/company/employee/photo/upload', data)
      .pipe(map(res => res.data));
  }

  uploadAndSetPhoto(model: EmployeeFormModel, file: Blob, fileName: string): Observable<FileInfo> {
    return this.uploadPhoto(file, fileName)
      .pipe(tap(({guid, url}) => {
        model.photo_file_guid = guid;
        model.photo_url = url;
      }));
  }

  setOwnPhoto(file: Blob, fileName: string) {
    return this.uploadPhoto(file, fileName)
      .pipe(
        switchMap(({guid}) => this.http
          .patch<ApiResponse<{ photo: FileInfo }>>('/api/v2/company/employee/self/photo', {photo_file_guid: guid})),
        map(it => it.data.photo),
      );
  }

  setSelfAboutMe(about) {
    return this.http.patch('/api/v2/company/employee/self/about', {about});
  }

  employeeByUserId(user_id: number) {
    return this.http.get<ApiResponse<EmployeeApiModel>>(`/api/v2/company/employee/by-user/${user_id}`)
      .pipe(map(it => it.data));
  }

  employeeByLogin(login: string) {
    return this.http.get<ApiResponse<EmployeeApiModel>>(`/api/v2/company/employee/by-login/${login}`)
      .pipe(
        laravelErrorsToInnerHTML(),
        map(it => it.data),
      );
  }
}
