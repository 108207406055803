import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorInterceptor } from './interceptors/error.interceptor';

export function AuthenticateByIP(authService: AuthService) {
  return authService.authenticateByIP().toPromise();
}

export const LoginUrl = 'login';
export const PasswordChangeUrl = 'password-change';
export const HappyBirthdayIliaUrl = 'Happy-Birthday-Ilia';


@Injectable()
export class AuthService {
  static get token() {
    return localStorage.getItem('token');
  }

  static set token(v: string) {
    localStorage.setItem('token', v);
  }

  static get adfsIdToken() {
    return localStorage.getItem('adfsIdToken');
  }

  static set adfsIdToken(v: string) {
    localStorage.setItem('adfsIdToken', v);
  }

  static removeToken() {
    localStorage.removeItem('token');
  }

  static removeAdfsIdTokenToken() {
    localStorage.removeItem('adfsIdToken');
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  constructor(private http: HttpClient, private injector: Injector) {
  }

  authenticateByIP() {
    if (!AuthService.token) {
      ErrorInterceptor.skipError();
      // return this.http.post<{ data: { token } }>('/api/auth/authenticate_user_by_ip', {})
      //   .pipe(
      //     tap(res => AuthService.token = res.data.token),
      //     catchError(e => {
      //       const url = window.location.pathname.slice(1);
      //       if ([LoginUrl, PasswordChangeUrl, HappyBirthdayIliaUrl].indexOf(url) === -1) {
      //         this.router.navigateByUrl('/' + LoginUrl);
      //       }
      //       return of();
      //     }),
      //   );
      this.router.navigateByUrl('/' + LoginUrl);
      return of();
    } else {
      return of();
    }
  }

  login(login, password) {
    return this.http.post('/api/auth/authenticate_user', { login, password })
      .pipe(
        tap((res: any) => {
          AuthService.token = res.data.token;
          this.router.navigateByUrl('/dashboard');
        }),
      );
  }

  requestPasswordChange(account: { account: string }) {
    return this.http.post('/api/v2/user/auth/reset-password', account);
  }

  changePassword(payload: { new_password: string, token: string }) {
    return this.http.post('/api/v2/user/auth/change-password', payload);
  }
}
