import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';

/*tslint:disable component-class-suffix use-host-property-decorator component-selector*/
@Component({
  selector: 'field-daterangepicker',
  template: `
    <input class="form-control"
           autocomplete="off"
           [owlDateTime]="dt1"
           [owlDateTimeTrigger]="dt1"
           [selectMode]="'range'"
           [rangeSeparator]="'-'"
           [min]="isObservable(to.minDate) ? (to.minDate | async) : to.minDate"
           [max]="isObservable(to.maxDate) ? (to.maxDate | async) : to.maxDate"
           [class.is-invalid]="showError"
           [formlyAttributes]="field"
           [formControl]="formControl"
    />
    <owl-date-time [pickerType]="'calendar'" #dt1 [panelClass]="field.templateOptions.pannelClass || ''"></owl-date-time>
  `,
})
export class DateRangepickerComponent extends FieldType {
  isObservable = isObservable;
  to: any;
}
