import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInCalendarDays, startOfYear } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiResponse } from 'shared/interfaces/response';
import { fromPHPDate } from 'shared/utils/form.utils';

export interface BusinessCalendarDayInterface {
    date: string;
    is_working: boolean;
}

export interface BusinessCalendarInterface {
    [key: number]: BusinessCalendarDayInterface;
}

@Injectable({ providedIn: 'root' })
export class BusinessCalendarService {

    private businessCalendar$ = new BehaviorSubject<BusinessCalendarInterface>(undefined);

    get businessCalendar() {
        return this.businessCalendar$.getValue();
    }

    constructor(private http: HttpClient) {
        this.getCalendar((new Date()).getFullYear()).subscribe((data) => this.businessCalendar$.next(data));
    }

    private getCalendar(year: number): Observable<BusinessCalendarInterface> {
        return this.http.get<ApiResponse<BusinessCalendarInterface>>(
            `/api/v2/business-calendar-full-year/${year}`).pipe(
            map(response => response.data),
            shareReplay(),
        );
    }

    lastWorkingDayFromDate(startDate: Date, interval: number): Date {
        const businessCalendar = this.businessCalendar;
        const days: BusinessCalendarDayInterface[] = [];
        let day = differenceInCalendarDays(startDate, startOfYear(startDate)) + 1;
        if (!!businessCalendar) {
            while (interval > 0) {
                if (businessCalendar[day].is_working) {
                    days.push(businessCalendar[day]);
                    interval--;
                }
                day++;
            }
            return fromPHPDate(days.pop().date);
        }
        return startDate;
    }
}
