import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LabelWrapperComponent } from './label-wrapper.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LabelWrapperComponent],
  exports: [LabelWrapperComponent],
})
export class LabelWrapperModule {
}
