<div class="input-group">
  <div class="input-group-prepend" *ngIf="to.addonLeft" [ngStyle]="{cursor: to.addonLeft.onClick ? 'pointer' : 'inherit'}"
    (click)="addonLeftClick($event)">
    <i class="input-group-text" [ngClass]="to.addonLeft.class" *ngIf="to.addonLeft.class"></i>
    <span *ngIf="to.addonLeft.text" class="input-group-text">{{ to.addonLeft.text }}</span>
  </div>
  <div class="input-addons">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div class="input-group-append" *ngIf="to.addonRight" [ngStyle]="{cursor: to.addonRight.onClick ? 'pointer' : 'inherit'}"
    (click)="addonRightClick($event)">
    <i class="input-group-text" [ngClass]="to.addonRight.class" *ngIf="to.addonRight.class"></i>
    <span *ngIf="to.addonRight.text" class="input-group-text">{{ to.addonRight.text }}</span>
  </div>
</div>
