import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface SelectedRows<T = any> {
  [index: number]: T;
}

export interface RowToRegister {
  index: number;
  row: any;
}

export interface RowButton {
  class: string;
  hint: string;
}

export interface ButtonClickedEvent<T = any> {
  buttonIndex: number;
  rowIndex: number;
  rowData: T;
}

@Injectable()
export class TableWithControlsService {
  private selectedRowsSubject$ = new BehaviorSubject<SelectedRows>({});
  private allRows: { [index: number]: any } = {};
  private isRowSelected: { [index: number]: boolean } = {};

  public get selectionChanged$() {
    return this.selectedRowsSubject$.asObservable();
  }

  private buttonsSubject$ = new BehaviorSubject<RowButton[]>([]);

  public get buttons$() {
    return this.buttonsSubject$.asObservable();
  }

  private buttonClickedSubject$ = new Subject<ButtonClickedEvent>();

  public get buttonClicked$() {
    return this.buttonClickedSubject$.asObservable();
  }

  private allRowsSelectedSubject$ = new BehaviorSubject<boolean>(false);

  public get allRowsSelected$() {
    return this.allRowsSelectedSubject$.asObservable();
  }

  selectable = true;

  constructor() {}

  registerRow(row: RowToRegister) {
    this.allRows[row.index] = row.row;
    this.isRowSelected[row.index] = false;
  }

  toggleRow(index: number) {
    this.isRowSelected[index] = !this.isRowSelected[index];
    this.update();
  }

  setAllRows(selected: boolean) {
    Object.keys(this.isRowSelected).forEach((rowIndex) => this.isRowSelected[rowIndex] = selected);
    selected
      ? this.selectedRowsSubject$.next(this.allRows)
      : this.selectedRowsSubject$.next({});
    this.updateHeaderCheckbox();
  }

  private update() {
    const selectedRows = Object.keys(this.isRowSelected)
      .filter(x => this.isRowSelected[x])
      .map(x => ({ [x]: this.allRows[x] }))
      .reduce((a, b) => ({ ...a, ...b }), {});
    this.selectedRowsSubject$.next(selectedRows);
    this.updateHeaderCheckbox();
  }

  private updateHeaderCheckbox() {
    this.allRowsSelectedSubject$.next(Object.keys(this.allRows).length === 0
      ? false
      : Object.keys(this.allRows).length === Object.keys(this.selectedRowsSubject$.value).length,
    );
  }

  setButtons(buttons: RowButton[]) {
    this.buttonsSubject$.next(buttons);
  }

  buttonClicked(e: ButtonClickedEvent) {
    this.buttonClickedSubject$.next(e);
  }

  dispose() {
    this.allRows = {};
    this.isRowSelected = {};
    this.selectedRowsSubject$.next({});
    this.buttonsSubject$.next([]);
  }

}
