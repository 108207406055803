import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DashboardService } from 'portal/pages/dashboard/dashboard.service';
import { AuthService } from 'portal/services/auth.service';
import { serializeGet } from 'shared/utils/utils';
import { NavbarContentService } from '../navbar-content.service';

@Component({
    selector: 'app-navbar',
    encapsulation: ViewEncapsulation.Emulated,
    template: `
        <nav #navbarNav swipe (swipeDown)="isMobile && collapseButtonHandle()" (swipeUp)="isMobile && collapseButtonHandle()" class="navbar navbar-expand-lg bg-light">
            <div class="container-fluid">
                <button
                    *ngIf="(navbarContent.showToggler$ | async)"
                    class="navbar-toggler border-0"
                    type="button"
                    aria-label="Toggle navigation"
                    (click)="toggle()"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <button *ngIf="!isMainPage" class="navbar-toggler" type="button" (click)="collapseButtonHandle()">
                    <span>Меню раздела</span>
                </button>
                <div class="navbar-collapse collapse" [class.show]="isCollapsed" id="navbarNav">
                    <ul
                        *ngIf="(navbarContent.templates$ | async) as templates; else pusher"
                        class="navbar-nav flex-grow-1 mb-2 mb-lg-0"
                    >
                        <li
                            *ngFor="let template of templates; let last = last"
                            [class.me-1]="!last"
                            [class.flex-grow-1]="last"
                            class="nav-item"
                        >
                            <ng-template [ngTemplateOutlet]="template"></ng-template>
                        </li>
                    </ul>

                    <ng-template #pusher>
                        <div class="d-flext flex-grow-1"></div>
                    </ng-template>

                    <div
                        *ngIf="(dashboardService.userInfo$ | async) as userInfo"
                        dropdown
                        placement="bottom right"
                        container="app-root"
                        class="ms-2 btn-group d-flex flex-row justify-content-end aligm-items-center"
                    >
                        <ul class="navbar-nav me-2">
                            <li class="nav-item d-none d-md-block">
                                <button
                                    *navbarItem="null; label: null; watchOverflow: false"
                                    class="btn btn-outline-secondary"
                                    (click)="handleSupportBtnClick()"
                                >
                                    <i class="fa emoji">🤝</i><span class="ms-2">Поддержка портала</span>
                                </button>
                            </li>
                            <li class="nav-item d-block d-md-none">
                                <h5 class="my-2 text-nowrap text-muted ">
                                    Добро пожаловать, {{ userInfo.employee ? userInfo.employee.name : userInfo.name }}!
                                </h5>
                            </li>
                        </ul>

                        <a dropdownToggle [routerLink]="[]">
                            <app-user-photo
                                [photoUrl]="userInfo?.employee?.photo_url"
                                [style.width]="'40px'"
                                [style.height]="'40px'"
                                [style.display]="'block'"
                            ></app-user-photo>
                        </a>

                        <ul
                            *dropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            role="menu"
                            aria-labelledby="button-basic"
                        >
                            <li role="menuitem">
                                <a [routerLink]="['u', userInfo?.login]" class="dropdown-item">Профиль</a>
                            </li>
                            <li *ngIf="isMobile" role="menuitem">
                                <a href="mailto:portalsupport@mediainstinctgroup.ru" class="nav-link">
                                    <i class="fa emoji">🤝</i><span class="ms-2">Поддержка портала</span>
                                </a>
                            </li>
                            <li class="divider dropdown-divider"></li>
                            <li role="menuitem"><ng-container *ngTemplateOutlet="logout"></ng-container></li>
                        </ul>
                    </div>
                </div>

                <ng-template #logout>
                    <a [href]="logoutUrl" container="body" tooltip="Выйти" class="dropdown-item">
                        <i class="fas fa-sign-out-alt"></i> Выход
                    </a>
                </ng-template>
            </div>
        </nav>
    `,
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
    @Output() toggleSidebar = new EventEmitter();
    isMobile = this.deviceService.isMobile();

    @ViewChild('navbarNav') navbarNav: ElementRef<HTMLElement>;

    isCollapsed = false;
    buttonCount = 0; // Количество кнопок с классом .btn
    private observer: MutationObserver; // Observer для отслеживания изменений

    get isMainPage() {
        return window.location.pathname === '/dashboard';
    }

    get logoutUrl(): string {
        const params = {
            post_logout_redirect_uri: window.location.origin + '/login',
            id_token_hint: AuthService.adfsIdToken,
        };

        return `https://sso.unseco.ru/adfs/oauth2/logout?${serializeGet(params)}`;
    }

    constructor(
        public navbarContent: NavbarContentService,
        public dashboardService: DashboardService,
        private deviceService: DeviceDetectorService
    ) {}
    ngAfterViewInit(): void {
        this.initMutationObserver();
    }

    ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect(); // Остановить наблюдение
        }
    }


    private initMutationObserver(): void {
        if (!this.navbarNav) {
            return;
        }

        const observerConfig: MutationObserverInit = {
            childList: true,
            subtree: true,
        };

        this.observer = new MutationObserver(() => {
            this.updateButtonCount();
        });

        this.observer.observe(this.navbarNav.nativeElement, observerConfig);

        this.updateButtonCount();
    }


    private updateButtonCount(): void {
        const navButtons = this.navbarNav?.nativeElement?.querySelectorAll<HTMLElement>('#navbarNav ul .btn');
        const currentCount = navButtons?.length ?? 0;
        const prevCount = this.buttonCount;

        if (currentCount !== prevCount) {
            this.buttonCount = currentCount;
            window.dispatchEvent(new Event('resize'));
        }
    }

    toggle() {
        this.toggleSidebar.emit();
        this.navbarContent.toggle();
    }

    collapseButtonHandle() {
        // console.log(this.isCollapsed);
        this.isCollapsed = !this.isCollapsed;
    }

    handleSupportBtnClick() {
        window.location.href = 'mailto:portalsupport@mediainstinctgroup.ru';
    }
}
