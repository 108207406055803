import { Component, EventEmitter, HostBinding, Inject, InjectionToken, Input, Optional, Output } from '@angular/core';

export const SEARCHBAR_PLACEHOLDER = new InjectionToken('SEARCHBAR_PLACEHOLDER');

@Component({
    selector: 'app-searchbar',
    template: `
        <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
        <div
            class="input-group position-relative flex-nowrap animated shadow-sm border-none {{
                (search && search.length) || show ? 'opacity-1' : ''
            }}"
        >
            <input
                *ngIf="!hideInput"
                type="text"
                class="form-control flex-grow-1"
                [placeholder]="_placeholder"
                [(ngModel)]="search"
            />
            <ng-content></ng-content>
        </div>
    `,
})
export class SearchbarComponent {
    @HostBinding('class.typed')
    private _search: string;
    get search() {
        return this._search;
    }

    get _placeholder() {
        return `🔍 ${this.placeholder}`;
    }

    @Input() set search(val) {
        this.searchChange.emit(val);
        this._search = val;
    }

    @Output() searchChange = new EventEmitter();
    @Input() show = false;
    @Input() hideInput = false;
    @Input() placeholder;

    constructor(@Optional() @Inject(SEARCHBAR_PLACEHOLDER) placeholder) {
        this.placeholder = placeholder || '';
    }
}
