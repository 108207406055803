import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { paramCase } from 'change-case';
import { DashboardService } from 'portal/pages/dashboard/dashboard.service';
import { ErrorInterceptor } from 'portal/services/interceptors/error.interceptor';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ApiResponse } from 'shared/interfaces/response';
import { PropStub } from 'shared/modules/table/table-config.model';
import { LocalStorage } from 'shared/utils/local-storage.decorator';
import { CeoBoardReport, PermissionsTree, PermissionsTreeV2, Section, UserAccessV2 } from './permissions.interface';
import {isBoolean, isNullOrUndefined} from 'shared/utils/utils';

export interface Permission {
    id?: number;
    alias: string;
    name: string;
    description?: any;
    created_at?: any;
    updated_at?: any;
    deleted_at?: any;
}

export interface RootAccess {
    root_access: boolean;
}

const isRootAccess = (it: any): it is RootAccess => it.root_access;
export type FullPermissionTree = { v1: Permission[], v2: PermissionsTreeV2 };
export const permStub = PropStub<PermissionsTree>();

@Injectable()
export class PermissionsService {
    permissions$ = new ReplaySubject<FullPermissionTree>(1);

    @LocalStorage({ initialValue: undefined })
    private permissionsV1: Permission[] | RootAccess;

    @LocalStorage({ initialValue: undefined })
    private permissionsV2: PermissionsTreeV2;

    @BindObservable()
    private ceoBoardReports: CeoBoardReport[] = [];
    ceoBoardReports$: Observable<CeoBoardReport[]>;

    @BindObservable()
    private sections: Section[] = [];
    sections$: Observable<Section[]>;

    constructor(private http: HttpClient, private dashboardService: DashboardService) {}

    private loadV1Permissions() {
        return this.http.post<ApiResponse<Permission[]>>('/api/access/get_allowed_privilege_list', {})
            .pipe(map(it => it.data));
    }

    private loadV2Permissions() {
        return this.http.get<ApiResponse<UserAccessV2>>(`/api/v2/user/access`).pipe(
            map(it => it.data),
        );
    }

    loadPermissions(): Observable<FullPermissionTree> {
        ErrorInterceptor.skipError(2);
        return forkJoin([this.loadV1Permissions(), this.loadV2Permissions()]).pipe(
            map(([v1, { permissions, ceo_board_reports, employee, user }]) => {
                if (ceo_board_reports) {
                    this.ceoBoardReports = ceo_board_reports;
                }
                user.employee = employee;
                this.dashboardService.userInfo = user;
                return { v1, v2: permissions };
            }),
            tap(({ v1, v2 }) => {
                this.permissionsV1 = v1;
                this.permissionsV2 = v2;
                this.sections = v2.Section;
            }),
            tap(permissions => this.permissions$.next(permissions)),
            finalize(() => ErrorInterceptor.eraseCounter()),
        );
    }

    removePermissions() {
        localStorage.removeItem('permissionsV1');
        localStorage.removeItem('permissionsV2');
    }

    isAdmin() {
        return isRootAccess(this.permissionsV1);
    }

    getPermissionValue(val: boolean): boolean {

        if (isBoolean(val)) {
            return val;
        }
        if (isNullOrUndefined(val)) {
            console.warn('Permission not found');
            return false;
        }

        const path = ((val as any).path as string[]);
        const isForV2 = path[0] === 'v2';
        if (!isForV2) {
            if (isRootAccess(this.permissionsV1)) {
                return true;
            } else {
                const requestedAlias = path.map((it) => paramCase(it)).join('@');
                return this.permissionsV1.map(it => it.alias).indexOf(requestedAlias) !== -1;
            }
        } else {
            path.shift();
            let val = this.permissionsV2;
            while (path.length) {
                val = val[path.shift()];
                if (val === undefined) { return false; }
            }
            return val as any;
        }
    }

    canControlSection(sectionPath): boolean {
        const section = this.permissionsV2.Section.filter(section => section.code === sectionPath);
        if (section.length === 0) {
            return false;
        }
        return section[0].control;
    }

    canConvertPage(sectionPath): boolean {
        const section = this.permissionsV2.Section.filter(section => section.code === sectionPath);
        if (section.length === 0) {
            return false;
        }
        return section[0].can_convert_page;
    }
}
