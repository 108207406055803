import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserPhotoComponent } from './user-photo.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [UserPhotoComponent],
    exports: [UserPhotoComponent],
})
export class UserPhotoModule {
}
