import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';
/** TODO
 * Make not searchable by default
 */

/*tslint:disable component-class-suffix component-selector*/
@Component({
    // changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'formly-field-select',
    template: `
        <ng-select
                [items]="isObservable(to.options) ? (to.options | async) : to.options"
                [formControl]="formControl"
                [searchable]="to.hasOwnProperty('searchable') ? to.searchable : true"
                [clearable]="!to.required"
                (change)="field.templateOptions.change ? field.templateOptions.change(field) : true"
                [addTag]="field.templateOptions.tagable ? addTagHandler : false"
                [multiple]="field.templateOptions.multiple ? field.templateOptions.multiple : false"
                bindValue="value">
            <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [title]="item.label">{{item.label}}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <span class="ng-option-label" [title]="item.label">{{item.label}}</span>
            </ng-template>
        </ng-select>
    `,
})
export class FormlyFieldSelect extends FieldType implements AfterViewInit {
    isObservable = isObservable;

    constructor() {
        super();
    }

    ngAfterViewInit(): void {
        if (!this.field.templateOptions.readonly) {
            this.formControl.enable();
        } else {
            this.formControl.disable();
        }
    }

    addTagHandler(s) {
        return { label: s, value: s, tag: true };
    }
}
