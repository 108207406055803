import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { defaultCrudFetchInitializer, NewDefaultCrudFetchService } from 'portal/chunks/default-crud/new-default-crud-fetch.service';
import {
  CreateStaffFormApiModel,
  StaffFormApiModel,
  UpdateAccountantStaffFormModel,
  UpdateStaffFormApiModel,
} from 'portal/pages/hr/staff-forms/staff-forms.interfaces';
import { Observable } from 'rxjs';
import { ApiResponse } from 'shared/interfaces/response';

export const staffFormPrefix = '/api/v2/staff-form';

@Injectable({ providedIn: 'root' })
export class StaffFormsFetchService extends NewDefaultCrudFetchService<StaffFormApiModel> {
  constructor(http: HttpClient) {
    super(defaultCrudFetchInitializer(staffFormPrefix), http);
  }

  nullify(s: StaffFormApiModel) {
    return this.http.post(`${staffFormPrefix}/${s.id}/nullify`, {});
  }

  publish(s: StaffFormApiModel) {
    return this.http.post(`${staffFormPrefix}/${s.id}/publish`, {});
  }

  saveAsHr(s: UpdateStaffFormApiModel) {
    return this.http.patch(`${staffFormPrefix}/${s.id}/hr`, this.preprocess(s));
  }

  sendToAccountant(s: StaffFormApiModel) {
    return this.http.post(`${staffFormPrefix}/${s.id}/send-to-accountant`, {});
  }

  saveAsAccountant(s: UpdateAccountantStaffFormModel) {
    return this.http.patch(`${staffFormPrefix}/${s.id}/accountant`, s);
  }

  sendToPersonnel(s: StaffFormApiModel) {
    return this.http.post(`${staffFormPrefix}/${s.id}/send-to-personnel`, {});
  }

  create(s: CreateStaffFormApiModel): Observable<ApiResponse<StaffFormApiModel>> {
    return super.create(this.preprocess(s));
  }

  private preprocess<T extends { probation_end_date: Date }>(v: T) {
    return {
      ...v,
      probation_end_date: v.probation_end_date ? format(v.probation_end_date, 'YYYY-MM-DD') : null,
    };
  }

}
