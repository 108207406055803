import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

/*tslint:disable component-selector component-class-suffix no-host-metadata-property*/
@Component({
    selector: 'formly-field-masked-input',
    template: `
        <input *ngIf="type !== 'number'; else numberTmp"
               [type]="type"
               [formControl]="formControl"
               class="form-control"
               [formlyAttributes]="field"
               [textMask]="{mask: to.mask}"
               [class.is-invalid]="showError">
        <ng-template #numberTmp>
            <input type="number"
                   [formControl]="formControl"
                   class="form-control"
                   [formlyAttributes]="field"
                   [textMask]="{mask: to.mask}"
                   [class.is-invalid]="showError">
        </ng-template>
    `,
    host: {
        '[class.d-inline-flex]': 'to.addonLeft || to.addonRight',
        '[class.custom-file]': 'to.addonLeft || to.addonRight',
    },
})
export class FormlyFieldMaskedInput extends FieldType {
    to: any;

    get type() {
        return this.to.type || 'text';
    }
}
