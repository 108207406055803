import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BindObservable } from 'bind-observable';
import { isArray, isNumber } from 'lodash-es';
import { EmployeeApiModel } from 'portal/pages/main/employees/employees.interfaces';
import {BehaviorSubject, combineLatest, iif, merge, Observable, of, Subject} from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeAll, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'typeahead-select',
    template: `
        <ng-select
                [(ngModel)]="value"
                [typeahead]="input$"
                [closeOnSelect]="true"
                [hideSelected]="true"
                bindLabel="label"
                bindValue="value"
                [loading]="loading"
                [multiple]="multiple"
                [disabled]="formControl ? formControl.disabled : false"
                [items]="items$ | async"
                [addTag]="taggable ? addTag : false"
                [typeToSearchText]="placeholder$ |async"
                (focusin)="focus$.next(true)"
                (focusout)="focus$.next(false)"
        >
        </ng-select>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TypeaheadSelectComponent),
            multi: true,
        },
    ],
})

export class TypeaheadSelectComponent implements ControlValueAccessor, OnInit {
    @Input()
    formControl: AbstractControl;

    @BindObservable()
    private _value?: number | number[] | string = undefined;
    private _value$!: Observable<number | number[] | string>;

    get value() {
        return this._value;
    }

    @Input()
    set value(val) {
        this._value = val;
        this.onChange(this._value);
    }

    @Input()
    queryParameters: any = {};

    public items$: Observable<{ value, label }[]>;
    public loading = false;
    public input$ = new Subject<string | null>();

    @Input()
    getListEndpoint: string;
    @Input()
    getOneEndpoint: string;
    @Input()
    bindLabel: string;
    @Input()
    bindValue: string;

    @Input()
    @BindObservable()
    initialState: { value, label }[] = [];
    initialState$: Observable<{ value, label }[]>;
    @Input()
    placeholder = '';
    @Input()
    multiple = false;
    @Input()
    disabled = false;
    @Input()
    taggable = false;

    @Input()
    maper = (m) => m;

    focus$ = new BehaviorSubject(false);

    placeholder$ = this.focus$.pipe(
        map(focus => focus && !!this.placeholder ? this.placeholder : 'Начните ввод для поиска')
    );

    constructor(
        private http: HttpClient,
    ) {
    }

    onChange(_: any) {

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(value: any): void {
        this.value = value;
    }

    search(term: string | null) {
        return this.http.get(this.getListEndpoint, { params: { search_string: term, ...this.queryParameters } }).pipe(
            map((x: any) => x.data.items.map(this.maper).map(y => ({
                value: this.bindValue ? y[this.bindValue] : y,
                label: y[this.bindLabel],
            }))),
        );
    }

    getOne(ident): Observable<{ value, label }[]> {
        return this.http.get(`${this.getOneEndpoint}/${ident}`).pipe(
            map((x: any) => ([{ value: this.bindValue ? x.data[this.bindValue] : x.data, label: x.data[this.bindLabel] }])),
        );
    }

    getMany(idents): Observable<{ value, label }[]> {
        return merge(idents.map(ident => this.getOne(ident)));
    }

    ngOnInit() {
        this.items$ = merge([
            this.initialState$.pipe(
                filter(state => !!state),
                distinctUntilChanged(),
                map((x: any) => x.map(y => ({ value: this.bindValue ? y[this.bindValue] : y, label: y[this.bindLabel] }))),
            ),
            this._value$.pipe(
                switchMap(v => iif(() => isNumber(v) || isArray(v),
                    isNumber(v) ? this.getOne(v).pipe(
                        tap(() => this.loading = false),
                        catchError(() => of([])),
                    ) : isArray(v) ? this.getMany(v).pipe(
                        mergeAll(),
                        tap(() => this.loading = false),
                        catchError(() => of([])),
                    ) : of([]), of([]),
                )),
            ),
            this.input$.pipe(
                filter(term => term !== null && term.length >= 3),
                distinctUntilChanged(),
                debounceTime(300),
                tap(() => this.loading = true),
                switchMap(term => this.search(term).pipe(
                    tap(() => this.loading = false),
                    catchError(() => of([])),
                )),
            ),
        ]).pipe(
            mergeAll(),
            tap(() => this.loading = false),
            catchError(() => of([])),
        );
    }

    addTag(counterpartyName: string) {
        return counterpartyName;
    }
}
