import { AfterViewInit, Directive, DoCheck, ElementRef, NgModule, OnDestroy } from '@angular/core';
import { nextTick } from 'q';
import { fromEvent, merge } from 'rxjs';

const evName = 'checkIfHaveValue';
const ev = new Event(evName, { bubbles: true });

/* Autofill issues */
document.addEventListener('animationstart', (e) => {
  if (e.animationName === 'onAutoFillStart') {
    const input: HTMLInputElement = e.srcElement as any;
    const wrapper = input.closest('.label-wrapper');
    const target = wrapper.firstElementChild;
    target.setAttribute('autofilled', '');
  }
});

/* For input elements */
merge(
  fromEvent(document.body, 'change'),
  fromEvent(document.body, evName),
).subscribe(e => {
  const input: HTMLInputElement = e.target as any;
  const value = input.value;
  const wrapper = input.closest('.label-wrapper');
  if (wrapper) {
    const target = wrapper.firstElementChild;
    if (value && value.length) {
      target.setAttribute('typed', '');
    } else if (e.type === 'change') {
      target.removeAttribute('typed');
      target.removeAttribute('autofilled');
    }
  }
});
/**/
fromEvent(document.body, 'focusin').subscribe(e => {
  const input: HTMLInputElement = e.target as any;
  const wrapper = input.closest('.label-wrapper');
  if (wrapper) {
    const target = wrapper.firstElementChild;
    target.setAttribute('focused', '');
  }
});
fromEvent(document.body, 'focusout').subscribe(e => {
  const input: HTMLInputElement = e.target as any;
  const wrapper = input.closest('.label-wrapper');
  if (wrapper) {
    const target = wrapper.firstElementChild;
    target.removeAttribute('focused');
  }
});
/**
 * @link Multiselect
 */

/*tslint:disable directive-class-suffix directive-selector*/
@Directive({
  selector: 'input, select, textarea',
})
export class EventDispatcher implements DoCheck {
  constructor(private _elementRef: ElementRef) {
  }

  ngDoCheck() {
    try {
      this._elementRef.nativeElement.dispatchEvent(ev);
    } catch (e) {}
  }
}

@Directive({
  selector: 'ng-select',
})
export class NgSelectEventDispatcher implements OnDestroy, AfterViewInit {
  get element() {
    return this._elementRef.nativeElement;
  }

  observer: MutationObserver;
  bindAttribute = () => {
    const wrapper = this.element.closest('.label-wrapper');
    if (wrapper) {
      const hasValue = this.element.firstElementChild &&
        this.element.firstElementChild.classList.contains('ng-has-value');
      hasValue
        ? wrapper.firstElementChild.setAttribute('typed', '')
        : wrapper.firstElementChild.removeAttribute('typed');
    }
  }

  constructor(private _elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit() {
    this.observer = new MutationObserver(this.bindAttribute);
    this.observer.observe(this.element.firstElementChild, { attributes: true });
    nextTick(this.bindAttribute);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}

@NgModule({
  declarations: [EventDispatcher, NgSelectEventDispatcher],
  exports: [EventDispatcher, NgSelectEventDispatcher],
})
export class EventDispatcherModule {
}
