import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { isObservable } from 'rxjs';
/** TODO
 * Make not searchable by default
 */

/*tslint:disable component-class-suffix component-selector*/
@Component({
    selector: 'formly-field-section-name-block',
    template: `
        <div class="border-top pt-3 mb-3">
            <span class="h5 font-weight-bolder">{{field.templateOptions.label }}</span>
        </div>
    `,
})
export class FormlyFieldSectionNameBlock extends FieldType {
    isObservable = isObservable;

    constructor() {
        super();
    }

}
