import { Component, CUSTOM_ELEMENTS_SCHEMA, HostBinding, Input, NO_ERRORS_SCHEMA, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-user-photo',
  template: `
      <div [routerLink]="!!login ? ['u', login] : []" class="wrapper d-flex align-items-center justify-content-center" [ngClass]="{'bg-gray': error}">
        <img [src]="photoUrl" (error)="onPhotoLoadingError()" class="img-fluid rounded-circle shadow" />
      </div>
    `,
})

export class UserPhotoComponent {
  @Input() photoUrl: string;
  @Input() userName: string;
  @Input() login: string;

  get initials() {
    if (this.userName) {
      return this.userName.split(' ').map((word) => word.charAt(0).toUpperCase()).join('');
    }
    return '';
  }

  @HostBinding('class.error')
  public error = false;

//   @HostBinding('style.width') get sWidth() { return !!this.width ? `${this.width}px` : '100%'; }
//   @HostBinding('style.height') get sHeight() { return !!this.height ? `${this.height}px` : 'auto'; }

  onPhotoLoadingError() {
    this.error = true;
  }
}
