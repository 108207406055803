/* tslint:disable */
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-form-field',
  template: `
      <div class="form-group" [class.has-error]="showError">
          <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id">
              {{ to.label }}
              <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>
          </label>

          <ng-template #fieldComponent></ng-template>

          <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
              <formly-validation-message [field]="field"></formly-validation-message>
          </div>

          <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
      </div>
  `,
})
export class FormlyWrapperFormField extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
