<app-drawer [align]="'left'" (initialized)="closeOnNavigation()" [width]="'290px'">
    <a class="logo" [routerLink]="'/dashboard'">
        <img src="/assets/images/mi-logo.svg" class="img-fluid">
        <!--<h3 class="">-->
        <!--    <span class="text-grey">MEDIA </span>-->
        <!--    <span class="text-primary">INSTINCT</span>-->
        <!--</h3>-->
    </a>
    <app-sidebar-menu-item *ngFor="let menuItem of menuItems" [menuItem]="menuItem"></app-sidebar-menu-item>
</app-drawer>
