import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defaultCrudFetchInitializer, NewDefaultCrudFetchService } from 'portal/chunks/default-crud/new-default-crud-fetch.service';
import { SingleFile } from 'portal/chunks/files/files.interfaces';
import { IFramelyResponse } from 'portal/pages/articles/preview';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ListPayload, ListResponse } from 'shared/interfaces/list';
import { ApiResponse } from 'shared/interfaces/response';

export interface ArticleCore {
    title: string;
    article_content: string;
    brief_description?: string;
    article_color?: string;
    file_guid_list?: string[];
    published?: boolean;
    category_id?: number;
}

export interface Article extends ArticleCore {
    id: number;
    title: string;
    article_content: string;
    brief_description: string;
    file_guid_list?: string[];
    published?: boolean;
    permissions: {
        can_edit: boolean;
        can_delete: boolean;
    };
    created_by_user_id: number;
    reactions: any;
    user_reaction: number;
    posts_count: number;
    published_at: string;
    author_user_name: string;
    author_user_id: number;
    file_list: SingleFile[];
}

export interface ArticleCategory {
    id?: number;
    name: string;
}

export interface ArticleCategoryAggregated extends ArticleCategory {
    articles_count: number;
}

const postproccessArticle = (it: Article) => ({ ...it, article_color: it.brief_description });

@Injectable({ providedIn: 'root' })
export class ArticlesFetchService extends NewDefaultCrudFetchService<Article> {
    constructor(http: HttpClient) {
        super(defaultCrudFetchInitializer('api/v2/news/article'), http);
    }

    private _oneTimeArticle: Article;

    setSingleArticle(a: Article) {
        this._oneTimeArticle = a;
    }

    getList(payload?: ListPayload): Observable<ListResponse<Article>> {
        return super.getList(payload).pipe(tap(({ data }) => data.items = data.items.map(postproccessArticle)));
    }

    get(payload): Observable<Article> {
        if (this._oneTimeArticle) {
            const result = of(this._oneTimeArticle);
            this._oneTimeArticle = undefined;
            return result;
        }
        return super.get(payload).pipe(map(postproccessArticle));
    }

    create(article: ArticleCore) {
        const content = article.article_content.split('<p class="break">&nbsp;</p>');
        return super.create({ ...article, brief_description: content.length > 1 ? content[0] : null });
    }

    update(article: ArticleCore) {
        const content = article.article_content.split('<p class="break">&nbsp;</p>');
        return super.update({ ...article, brief_description: content.length > 1 ? content[0] : null });
    }

    publish(article: Article) {
        return super.update({ ...article, published: true })
            .pipe(map(it => it.data));
    }

    preview(url: string): Observable<IFramelyResponse> {
        return this.http.post<ApiResponse<{ preview_data: IFramelyResponse }>>('api/v2/news/url-preview', { url })
            .pipe(map(it => it.data.preview_data));
    }

    getCategories(): Observable<ArticleCategory[]> {
        return this.http.get<ApiResponse<ArticleCategory[]>>('api/v2/news/category/list').pipe(
            map(response => response.data),
            shareReplay(),
        );
    }

    getCategoriesAggregated(): Observable<ArticleCategoryAggregated[]> {
        return this.http.get<ApiResponse<ArticleCategoryAggregated[]>>('api/v2/news/category/aggregated-list').pipe(
            map(response => response.data),
            shareReplay(),
        );
    }
}
