<div class="modal-header justify-content-between align-items-center">
    <div>
        <ng-content select=".header"></ng-content>
        <h5 class="modal-title">{{ title }}</h5>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <ng-content select=".custom-html"></ng-content>
        <button (click)="close()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
</div>

<ng-content></ng-content>

