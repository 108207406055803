<ng-container *ngIf="template">
    <ng-template [ngTemplateOutlet]="template"
                 [ngTemplateOutletContext]="{$implicit: cellData}"
    ></ng-template>
</ng-container>
<ng-container *ngIf="!template">
    <ng-container *ngIf="data.component">
        <ng-template [ngComponentOutlet]="data.component"
                     [ngComponentOutletInjector]="injectorToProvide"
        ></ng-template>
    </ng-container>
</ng-container>
