import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ModalService } from 'shared/modules/modal/modal.service';
import { WizardSteps } from 'shared/modules/modal/wizard-modal/wizard-modal.component';
import { f, PropStub } from 'shared/modules/table/table-config.model';
import { minutesFromMidnightToTime, timeToMinutesFromMidnight } from '../../main/booking/templates/templates.component';
import { PermissionsService, permStub } from 'portal/services/permissions.service';

export interface SystemSettingsApiModel {
  booking_timetable_step_in_mins: number;
  booking_max_period_in_days: number;
  booking_day_begin_in_mins: number;
  booking_day_end_in_mins: number;
  vacation_application_threshold_days: number;
  send_email_employee_probation_daily_at: string;
  send_email_birthday_daily_at: string;
}

export interface SystemSettingsFormModel {
  booking_timetable_step_in_mins: number;
  booking_max_period_in_days: number;
  booking_day_begin_in_mins: Date;
  booking_day_end_in_mins: Date;
  vacation_application_threshold_days: number;
  send_email_employee_probation_daily_at: Date;
  send_email_birthday_daily_at: Date;
}

export type differentTypeProps = 'booking_day_begin_in_mins' | 'booking_day_end_in_mins'
  | 'send_email_employee_probation_daily_at' | 'send_email_birthday_daily_at';

const ss = PropStub<SystemSettingsApiModel>();

export function TimeStringToDate(v: string) {
  const d = new Date;
  const [hours, minutes] = v.split(':').map((it) => parseInt(it, 10));
  d.setHours(hours, minutes);
  return d;
}

export function DateToTimeString(d: Date) {
  return d.toLocaleTimeString().split(':').slice(0, 2).join(':');
}

@Injectable()
export class SystemSettingsModal implements CanActivate {
  private form: WizardSteps[] = [
    {
      label: 'Настройки бронирования',
      form: [
        {
          key: f(ss.booking_timetable_step_in_mins),
          type: 'input',
          templateOptions: {
            label: 'Шаг страницы (минут)',
            type: 'number',
          },
        },
        {
          key: f(ss.booking_max_period_in_days),
          type: 'input',
          templateOptions: {
            label: 'Доступный период бронирования (дней)',
            type: 'number',
          },
        },
        {
          key: f(ss.booking_day_begin_in_mins),
          type: 'date',
          templateOptions: {
            label: 'Время начала дня',
            pickerType: 'timer',
          },
        },
        {
          key: f(ss.booking_day_end_in_mins),
          type: 'date',
          templateOptions: {
            label: 'Время окончания дня',
            pickerType: 'timer',
          },
        },
      ],
    },
    {
      label: 'Настройки отпусков',
      form: [
        {
          key: f(ss.vacation_application_threshold_days),
          type: 'input',
          templateOptions: {
            label: 'Мин. срок до отпуска (дней)',
            type: 'number',
          },
        },
      ],
    },
    {
      label: 'Настройки email-рассылки',
      form: [
        {
          key: f(ss.send_email_employee_probation_daily_at),
          type: 'date',
          templateOptions: {
            label: 'Время отправки уведомлений HR об окончании испытательного срока',
            pickerType: 'timer',
          },
        },
        {
          key: f(ss.send_email_birthday_daily_at),
          type: 'date',
          templateOptions: {
            label: 'Время отправки уведомлений о днях рождениях',
            pickerType: 'timer',
          },
        },
      ],
    },
  ];
  private canEdit: boolean;

  constructor(private router: Router,
              private modal: ModalService, private http: HttpClient,
              private permissions: PermissionsService) {
    this.canEdit = this.permissions.getPermissionValue(permStub.system.config.edit);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (window.location.pathname === '/system-settings') {
      this.router.navigateByUrl('/');
    }
    this.http.post('/api/system/get_config', {}).pipe(
      map((value: any) => value.data),
      switchMap((apiModel: SystemSettingsApiModel) => {
        const changes: Pick<SystemSettingsFormModel, differentTypeProps> = {
          booking_day_begin_in_mins: minutesFromMidnightToTime(apiModel.booking_day_begin_in_mins),
          booking_day_end_in_mins: minutesFromMidnightToTime(apiModel.booking_day_end_in_mins),
          send_email_employee_probation_daily_at: TimeStringToDate(apiModel.send_email_employee_probation_daily_at),
          send_email_birthday_daily_at: TimeStringToDate(apiModel.send_email_birthday_daily_at),
        };
        const model: SystemSettingsFormModel = { ...apiModel, ...changes };
        const onSubmit = (formModel) => of(formModel).pipe(
          map((formModel) => {
            const changes: Pick<SystemSettingsApiModel, differentTypeProps> = {
              booking_day_begin_in_mins: timeToMinutesFromMidnight(formModel.booking_day_begin_in_mins),
              booking_day_end_in_mins: timeToMinutesFromMidnight(formModel.booking_day_end_in_mins),
              send_email_employee_probation_daily_at: DateToTimeString(formModel.send_email_employee_probation_daily_at),
              send_email_birthday_daily_at: DateToTimeString(formModel.send_email_birthday_daily_at),
            };
            return { ...formModel, ...changes };
          }),
          switchMap((model: SystemSettingsApiModel) => this.http.post('/api/system/change_config', model)),
        );

        const payload = {
          title: 'Настройки системы',
          form: this.form,
          model,
          onSubmit,
        };

        return this.canEdit
          ? this.modal.updateWizardForm(payload)
          : this.modal.viewWizardForm(payload);
      }),
    ).subscribe();
    return false;
  }
}
