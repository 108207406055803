import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { drawerMixin } from 'hy-drawer/src/mixin';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BreakpointsAliases, MediaQueryService } from 'shared/media-query.service';

const resizeEvent = new Event('resize');

export class VanillaComponent {
  constructor(el, props) {
    (this as any).setupComponent(el, props);
    (this as any).connectComponent();
  }
}

export class HyDrawer extends drawerMixin(VanillaComponent) {
  constructor(...args) {
    super(...args);
  }

  setupShadowDOM(el) {
    if (!el) {
      throw Error('No element provided');
    }
    return el;
  }
}


@Component({
  selector: 'app-drawer',
  exportAs: 'drawer',
  template: `
      <div [style.minWidth.px]="animation$ | async"></div>
      <aside #drawer (hy-drawer-transitioned)="transitionEnd$.next()">
          <div class="hy-drawer-scrim"></div>
          <div class="hy-drawer-content"
               [style.right]="align === 'right' ? '-' + width : 'inherit'"
               [style.left]="align === 'left'  ? '-' + width : 'inherit'"
               [style.width]="width">
              <ng-content></ng-content>
          </div>
      </aside>
  `,
})
export class DrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() align: 'left' | 'right';
  @Input() width = '290px';
  @Input() closesOn$: Observable<boolean> = this.mq.lessOrEqual(BreakpointsAliases.md);
  @Output() initialized = new EventEmitter();

  @ViewChild('drawer', { static: true }) drawerRef: ElementRef;

  get drawerEl() {
    return this.drawerRef.nativeElement;
  }

  drawer: any;

  transitionEnd$ = new Subject<any>();
  shouldBeOpened$: Observable<boolean>;
  shouldBePersistent$: Observable<boolean>;

  animation$: Observable<number>;

  private destroyed$ = new Subject<any>();

  constructor(private mq: MediaQueryService) {
  }

  ngOnInit() {
    this.shouldBePersistent$ = this.closesOn$.pipe(switchMap(isSmall => isSmall
      ? this.transitionEnd$.pipe(map(() => false))
      : of(true),
    ));
    this.shouldBeOpened$ = this.closesOn$.pipe(map(it => !it));
  }

  async ngAfterViewInit() {
    const shouldBeOpened = await this.shouldBeOpened$.pipe(first()).toPromise();

    this.drawer = new HyDrawer(this.drawerEl, {
      opened: shouldBeOpened,
      align: this.align,
    });
    this.initialized.emit(this.drawer);

    const drawerPosition$: Observable<number> = this.drawer.translateX$.pipe(startWith(this.drawer.translateX));
    this.animation$ = combineLatest([drawerPosition$, this.shouldBeOpened$])
      .pipe(map(([translateValue, shouldBeOpened]) => shouldBeOpened ? Math.abs(translateValue) : 0));

    combineLatest([this.shouldBeOpened$, this.shouldBePersistent$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([shouldBeOpened, shouldBePersistent]) => {
        this.drawer.mouseEvents = !shouldBeOpened;
        // this.drawer.touchEvents = !shouldBeOpened;
        this.drawer.persistent = shouldBePersistent;
      });

    this.shouldBeOpened$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((shouldBeOpened) => {
        shouldBeOpened
          ? this.drawer.open(true)
          : this.drawer.close(true);
      });
    this.transitionEnd$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => window.dispatchEvent(resizeEvent));
  }

  ngOnDestroy() {
    this.drawer.disconnectComponent();
    this.destroyed$.next();
  }
}
