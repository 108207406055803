import { Injectable } from '@angular/core';
import { BindObservable } from 'bind-observable';
import { mapSize } from 'portal/pages/main/place-booking/old-map/map/constants';
import { combineLatest, fromEvent, merge, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, pairwise, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MouseButton } from 'shared/utils/mouse-buttons';
import { MouseCoordinates } from 'shared/utils/selection';

const minMax = (min: number, v: number, max: number) => v > max ? max
  : v < min ? min : v;

const defaultOptions = {
  zoomStep: .5,
  maxZoom: 5,
  minZoom: 1,
};

export type ZoomOptions = typeof defaultOptions;

@Injectable({ providedIn: 'root' })
export class Zoom {
  @BindObservable()
  enabled = true;
  private enabled$!: Observable<boolean>;

  @BindObservable()
  private scale = 1;
  private scale$!: Observable<number>;
  @BindObservable()
  private translateX = 0;
  private translateX$!: Observable<number>;
  @BindObservable()
  private translateY = 0;
  private translateY$!: Observable<number>;

  @BindObservable()
  private transition = '';
  private transition$!: Observable<string>;
  private transform$ = combineLatest([this.scale$, this.translateX$, this.translateY$]).pipe(
    map(([scale, translateX, translateY]) => `translate3d(${translateX * 100}%, ${translateY * 100}%, 0) scale(${scale})`),
  );

  canBePanned$ = combineLatest([
    this.scale$,
    this.enabled$,
  ]).pipe(map(([scale, enabled]) => scale > 1 && enabled));

  output$ = combineLatest([this.transform$, this.transition$])
    .pipe(map(([transform, transition]) => ({ transform, transition })), shareReplay(1));

  private el: HTMLElement | SVGElement;
  private relativeToEl: HTMLElement | SVGElement;

  get connected() {
    return !!this.el;
  }

  private options = defaultOptions;

  constructor() {
  }

  private sub = new Subscription();

  private get maxTranslate() {
    return Math.abs((this.scale - 1) * .5);
  }

  zoomIn() {
    this.transition = 'transform .1s ease-in';
    this.setScaleConsideringBounds(this.scale + this.options.zoomStep);
    this.setTranslateConsideringBounds(this.translateX, this.translateY);
  }

  zoomOut() {
    this.transition = 'transform .1s ease-in';
    this.setScaleConsideringBounds(this.scale - this.options.zoomStep);
    this.setTranslateConsideringBounds(this.translateX, this.translateY);
  }

  dispose() {
    this.el = undefined;
    this.sub.unsubscribe();
    this.sub = new Subscription();
  }

  private setTranslateConsideringBounds(translateX: number, translateY: number) {
    this.translateX = minMax(-this.maxTranslate, translateX, this.maxTranslate);
    this.translateY = minMax(-this.maxTranslate, translateY, this.maxTranslate);
  }

  private setScaleConsideringBounds(scale: number) {
    this.scale = minMax(this.options.minZoom, scale, this.options.maxZoom);
  }

  applyToElement(el: HTMLElement | SVGElement) {
    const sub = this.output$
      .pipe(
        finalize(() => {
          el.style.transform = '';
          el.style.transition = '';
        }),
      )
      .subscribe(({ transform, transition }) => {
        el.style.transition = transition;
        el.style.transform = transform;
      });
    this.sub.add(sub);
  }

  getRelativeCoordinatesOfPointer(mousePointer: MouseCoordinates) {
    const area = this.relativeToEl.getBoundingClientRect();
    const round = n => Math.floor(n * mapSize) / mapSize;
    const x = round((mousePointer.clientX - area.left) / area.width);
    const y = round((mousePointer.clientY - area.top) / area.height);

    return { x, y };
  }

  showPoint({ x, y }, atScale = 3) {
    this.transition = 'transform .3s ease-in';
    this.scale = atScale;
    this.setTranslateConsideringBounds((.5 - x) * atScale, (.5 - y) * atScale);
  }

  connect(el: HTMLElement | SVGElement, relativeToEl?: HTMLElement | SVGElement) {
    this.el = el;
    this.relativeToEl = relativeToEl || el;
    const pxToPercent$ = merge(
      fromEvent(document, 'load'),
      fromEvent(window, 'resize'),
    ).pipe(
      startWith(''),
      switchMap(() => new Promise(res => requestAnimationFrame(res))),
      map(() => {
        const { height, width } = this.el.getBoundingClientRect();
        return {
          height, width,
          x: (px: number) => px / width,
          y: (px: number) => px / height,
        };
      }),
    );
    const mousePointer$ = fromEvent(document, 'mousemove').pipe(
      map((e: MouseEvent) => ({ clientX: e.clientX, clientY: e.clientY })),
    );

    const fraction$ = mousePointer$.pipe(
      map((mousePointer) => this.getRelativeCoordinatesOfPointer(mousePointer)),
    );

    const mouseWheelDirection$ = fromEvent<WheelEvent>(this.el, 'wheel');

    const zoomSub = combineLatest([mouseWheelDirection$, fraction$]).pipe(
      filter(() => this.enabled),
      distinctUntilChanged((before, after) => before[0] === after[0]),
      tap(() => this.transition = 'transform .1s ease-in'),
    ).subscribe(([wheelEvent, fraction]) => {
      const wheelDirection = wheelEvent.deltaY > 0;
      const x = (fraction.x - .5) * this.options.zoomStep;
      const y = (fraction.y - .5) * this.options.zoomStep;

      const scale = this.scale + (wheelDirection ? this.options.zoomStep : -this.options.zoomStep);
      this.setScaleConsideringBounds(scale);

      const translateX = this.translateX + (wheelDirection ? -x : x);
      const translateY = this.translateY + (wheelDirection ? -y : y);
      this.setTranslateConsideringBounds(translateX, translateY);
    });
    this.sub.add(zoomSub);

    const mouseDeltas$ = fromEvent<MouseEvent>(el, 'mousedown').pipe(
      filter(e => e.button === MouseButton.LEFT),
      switchMap(() => mousePointer$
        .pipe(
          pairwise(),
          map(([before, after]) => ({
            dx: after.clientX - before.clientX,
            dy: after.clientY - before.clientY,
          })),
          takeUntil(fromEvent(document, 'mouseup')),
        ),
      ),
    );

    const panSub = combineLatest([mouseDeltas$, pxToPercent$]).pipe(
      filter(() => this.enabled),
      distinctUntilChanged((before, after) => before[0] === after[0]),
      tap(() => this.transition = ''),
    ).subscribe(([{ dx, dy }, convert]) => {

      const translateX = this.translateX + convert.x(dx);
      const translateY = this.translateY + convert.y(dy);
      this.setTranslateConsideringBounds(translateX, translateY);
    });
    this.sub.add(panSub);

    const boundElSub = this.applyToElement(el);
    this.sub.add(boundElSub);
  }
}
