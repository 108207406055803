import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ColorTwitterModule } from 'ngx-color/twitter';
import { FilesListModule } from 'portal/chunks/files/files-handler/files-list.module';
import { MathCeilPipeModule } from 'portal/pipes/math-ceil.pipe';
import { TextEditorComponent } from 'shared/modules/forms/text-editor/text-editor.component';



@NgModule({
  declarations: [
      TextEditorComponent
  ],
  imports: [
    CommonModule,
    ContenteditableValueAccessorModule,
    NgSelectModule,
    FormsModule,
    FilesListModule,
    ColorTwitterModule,
    BsDropdownModule,
    TooltipModule,
    PopoverModule.forRoot(),
    MathCeilPipeModule,
    CollapseModule.forRoot(),
  ]
})
export class TextEditorModule { }
