import { NgModule } from '@angular/core';

import { SwitchComponent } from './switch.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [SwitchComponent],
  exports: [SwitchComponent],
})
export class SwitchModule {
}
