import { coerceArray } from '@angular/cdk/coercion';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SelectOptions } from 'shared/modules/forms/options.interface';
import { Ctor } from 'shared/types';

export const distinctUntilArrayChanged = () => <T extends Array<any>>(source: Observable<T>) =>
    source.pipe(distinctUntilChanged((a, b) => {
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }));
export const toSelectOptions = () =>
    <T extends { id: number, name: string }>(source: Observable<T[]>): Observable<SelectOptions> =>
        source.pipe(map(it => it.map(it => ({ value: it.id, label: it.name }))));

export const selectOptionsToMap = () => (source: Observable<SelectOptions>) => source
    .pipe(
        map(selectOpions => selectOpions
            .map(({ label, value }) => ({ [value]: label }))
            .reduce((a, b) => ({ ...a, ...b }), {}),
        ),
    );

export const filterArraysWithNullOrUndefinedValues = () =>
    <T extends Array<any>>(source: Observable<T>) =>
        source.pipe(filter(args => !args.reduce((a, b) => a && (b === null || b === undefined), false)));

export const filterType = <T, C>(ctor: Ctor<C>) => (source: Observable<T>): Observable<Exclude<T, C>> => source.pipe(
    filter<T, Exclude<T, C>>((v): v is Exclude<T, C> => !(v instanceof ctor)),
);
export const ofType = <T, C extends T>(ctor: Ctor<C>) => (source: Observable<T>): Observable<C> => source.pipe(
    filter((v): v is C => v instanceof ctor),
);

export const laravelErrorsToInnerHTML = <T>(): OperatorFunction<T, T> => catchError(e => {
    // tslint:disable:no-string-throw
    if (e instanceof HttpErrorResponse && e.status === 500) {
        throw `<span class="font-weight-bold">500. Ошибка сервера</span>`;
    }

    if (e instanceof HttpErrorResponse && e.error && e.error.error_message) {
        throw `<span class="font-weight-bold">${e.error.error_message}</span><div class="ms-3">${(
            [].concat(
                ...Object
                    .values(e.error.data || {})
                    .map(errorMessages => coerceArray(errorMessages).map(eMsg => `<p>${eMsg}</p>`)),
            ).join('')
        )}</div>`;
    }

    throw e;
});

export const forkJoinOrEmpty = <T>(v: Observable<T>[]): Observable<T[]> => v.length ? forkJoin(v) : of([]);
