import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, TemplateRef } from '@angular/core';
import { ConfigViewerPayload, DataProviderFactory } from './config-viewer-settings.models';

@Component({
    selector: '[config-viewer]',
    templateUrl: 'config-viewer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigViewerComponent<T, P extends ConfigViewerPayload> implements OnChanges {
    @Input() data: P;
    @Input() template: TemplateRef<any>;

    public cellData: T;
    public injectorToProvide: Injector;


    constructor(private injector: Injector, private dataProviderFactory: DataProviderFactory<T, P>) {}

    ngOnChanges() {
        this.cellData = this.dataProviderFactory.create(this.data);
        this.injectorToProvide = Injector.create({
            providers: [
                {
                    provide: this.dataProviderFactory.ctor,
                    useValue: this.cellData,
                },
            ],
            parent: this.injector,
        });
    }
}
