import { FileWrapper, SingleFile } from 'portal/chunks/files/files.interfaces';
import { AuthService } from 'portal/services/auth.service';

export const fileIdToLink = (fileId) => `/api/file/${fileId}?token=${AuthService.token}&ngsw-bypass`;
export const photoFileIdToLink = (fileId) => `/api/file/${fileId}&ngsw-bypass`;
export const fileIdToDownloadLink = (fileId) => `/api/v2/filestorage/download/${fileId}?token=${AuthService.token}&ngsw-bypass`;
export const fileToStaticDownloadLink = (file: SingleFile) => {
    return `${file.url}?title=${encodeURI(file.basename)}`;
};
// photos
export const noPhotoSrc = `./assets/images/no-image.png`;
export const getPhotoUrl = (fileId: string) => fileId
  ? photoFileIdToLink(fileId)
  : noPhotoSrc;
