import { Component } from '@angular/core';
import { CellData } from 'shared/modules/table/table-config.model';
import { Utils } from 'shared/utils/utils';
import { BookingRow } from '../booking.interfaces';

/*tslint:disable component-class-suffix*/
@Component({
  selector: 'app-time-from-time-to-cell',
  templateUrl: './time-from-time-to-cell.html',
})
export class TimeFromTimeToCell {
  time: string;

  constructor(data: CellData<BookingRow>) {
    const time_from = new Date(Utils.now);
    const time_to = new Date(Utils.now);
    time_from.setMinutes(data.row.time_from);
    time_to.setMinutes(data.row.time_to);
    this.time = [time_from, time_to].map(t => t.toTimeString().slice(0, 5)).join(' - ');
  }
}
