import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CellData } from 'shared/modules/table/table-config.model';
import { SelectableService } from 'shared/modules/table/table-with-controls/control-cell/selectable.service';
import { isNullOrUndefined } from 'shared/utils/utils';
import { TableWithControlsService } from '../table-with-controls.service';


@Component({
    selector: 'app-control-cell',
    templateUrl: './control-cell.component.html',
})
export class ControlCellComponent implements AfterViewInit, OnDestroy {
    @ViewChild('checkbox') checkbox: ElementRef<HTMLInputElement>;
    selected = false;

    get hostElement(): HTMLElement {
        return this.elRef.nativeElement;
    }

    get trElement(): HTMLElement {
        return this.hostElement.closest('tr');
    }

    get isColumnHeader() {
        return isNullOrUndefined(this.cellData.row);
    }

    private sub = new Subscription();

    constructor(private elRef: ElementRef,
                public cellData: CellData,
                public controlCellService: TableWithControlsService,
                public selectableService: SelectableService,
    ) {
    }

    ngAfterViewInit() {
        if (this.controlCellService.selectable) {
            this.isColumnHeader
                ? this.registerSelectableHeader()
                : this.registerSelectable();
        }
    }

    private registerSelectable() {
        this.controlCellService.registerRow({ index: this.cellData.index, row: this.cellData.row });
        const onSelectionChangedSub = this.controlCellService.selectionChanged$
            .subscribe((x) => {
                const isRowSelected = !!x[this.cellData.index];
                this.setSelection(isRowSelected);
            });
        this.sub.add(onSelectionChangedSub);
    }

    private registerSelectableHeader() {
        const onAllRowsSelectefSub = this.controlCellService.allRowsSelected$
            .subscribe(v => this.checkbox.nativeElement.checked = v);
        this.sub.add(onAllRowsSelectefSub);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    toggleRow(index) {
        this.controlCellService.toggleRow(index);
        this.selectableService.toggleRowSelection(this.cellData.row);
    }

    toggleAllRows($event) {
        this.controlCellService.setAllRows($event.target.checked);

        if ($event.target.checked === false) {
            this.selectableService.reset();
        }

        this.controlCellService.selectionChanged$.subscribe(rows => {
            for (const index in rows) {
                if (rows.hasOwnProperty(index)) {
                    this.selectableService.toggleRowSelection(rows[index]);
                }
            }
        });
    }

    setSelection(selected: boolean) {
        this.selected = selected;
        const trClassList = this.trElement.classList;
        this.selected
            ? trClassList.add('table-warning')
            : trClassList.remove('table-warning');
        this.selected
            ? this.checkbox.nativeElement.checked = true
            : this.checkbox.nativeElement.checked = false;
    }

    buttonClicked(buttonIndex: number) {
        this.controlCellService.buttonClicked({
            buttonIndex,
            rowIndex: this.cellData.index,
            rowData: this.cellData.row,
        });
    }
}
