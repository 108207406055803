import { HttpClient } from '@angular/common/http';
import {
    Component,
    ContentChild,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BindObservable } from 'bind-observable';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, tap } from 'rxjs/operators';
import { PAGINATION_WRAPPER_QUERY_SERIALIZER, QueryEncoder } from 'shared/modules/table/pagination-wrapper/project-table-queries';
import { TableWithControlsComponent } from '../table-with-controls/table-with-controls/table-with-controls.component';
import { TableComponent } from '../table/table.component';

@Component({
    selector: 'app-pagination-wrapper',
    templateUrl: './pagination-wrapper.component.html',
})
export class PaginationWrapperComponent implements OnChanges, OnDestroy {
    @Input() query$: Observable<{ url: string, body: any }>;
    @Output() emptyData = new EventEmitter<boolean>();
    @ContentChild(TableComponent) justTableCmp: TableComponent;
    @ContentChild(TableWithControlsComponent) tableWithControlsCmp: TableComponent;

    get tableCmp(): TableComponent | TableWithControlsComponent {
        return this.justTableCmp || this.tableWithControlsCmp;
    }

    @BindObservable()
    offset = 0;
    private offset$!: Observable<number>;

    @BindObservable()
    limit = 30;
    private limit$!: Observable<number>;

    private customBodySubject$ = new BehaviorSubject<any>({});

    isLoading$ = new BehaviorSubject(false);

    @HostBinding('class.has-items') count: number;
    @HostBinding('class.pristine') isPristine = true;

    @HostBinding('class.loading') get isLoading() { return this.isLoading$.value; }

    subscriptions = new Subscription();

    constructor(
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(PAGINATION_WRAPPER_QUERY_SERIALIZER) private queryEncoder: QueryEncoder,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();

        const fromQueryParamsToTable = this.route.queryParams
            .subscribe(({ offset, limit }) => {
                // console.log(limit, offset);
                if (offset) {
                    this.offset = parseInt(offset, 10);
                }
                if (limit) {
                    this.limit = parseInt(limit, 10);
                }
            });
        const paginationQueryParams$ = combineLatest([
            this.offset$.pipe(distinctUntilChanged()),
            this.limit$.pipe(distinctUntilChanged()),
        ]).pipe(map(([offset, limit]) => ({ offset, limit })), tap((it) => {}));

        const fromParamsToRouterSub = paginationQueryParams$.pipe(
            tap(({ offset, limit }) => {
                const appliedQueryParams = Object.keys(this.route.snapshot.queryParams);
                const replaceUrl = appliedQueryParams.indexOf('offset') === -1 && appliedQueryParams.indexOf('limit') === -1;
                this.router.navigate(
                    ['./'],
                    { relativeTo: this.route, queryParams: { offset, limit }, replaceUrl, queryParamsHandling: 'merge' },
                );
            }),
        ).subscribe();

        const queryWithPagination$ = combineLatest([
            this.query$,
            this.customBodySubject$,
            paginationQueryParams$,
        ]).pipe(
            tap(() => this.isLoading$.next(true)),
            this.queryEncoder(this.http, (count) => this.count = count, (e) => {
                this.isLoading$.next(false);
                return of();
            }, this.route),
        );
        const updateTableSub = queryWithPagination$.subscribe((rows) => {
            this.emptyData.emit(rows.length === 0);
            this.isLoading$.next(false);
            this.isPristine = false;
            this.tableCmp.rows = rows;
            this.tableCmp.cdRef.detectChanges();

        });

        const dropOffsetSub = merge(this.query$.pipe(skip(1)), this.customBodySubject$.pipe(skip(1)))
            .subscribe((query) => {
                if (query.body) {
                    if ('search_string' in query.body) {
                        this.offset = 0;
                    }
                }
            });

        this.subscriptions.add(fromQueryParamsToTable);
        this.subscriptions.add(fromParamsToRouterSub);
        this.subscriptions.add(updateTableSub);
        this.subscriptions.add(dropOffsetSub);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    setCustomBody(val) {
        this.customBodySubject$.next(val);
    }

    getCustomBody() {
        return this.customBodySubject$.value;
    }

    reload() {
        this.customBodySubject$.next(this.customBodySubject$.value);
    }

}
