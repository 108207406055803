import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { makeEnum } from 'shared/utils/make-enum';

// export const Floor = makeEnum('6b', '6c', '7b', '7c', '8c');
export const Floor = makeEnum('6c', '7c', '8c', 'В');
export type Floor = keyof typeof Floor;

@Injectable()
export class MapAssetsService {
  private _cache = <{ [key in Floor]: Observable<string> | undefined }>{};
  constructor(private http: HttpClient) {}

  getFloorSvg$(floor: Floor) {
    if (!this._cache[floor]) {
      this._cache[floor] = this.http.get(`./assets/floors/${floor}.svg`, { responseType: 'text' }).pipe(map(this.removeTitleTag))
        .pipe(shareReplay(1));
    }
    return this._cache[floor];
  }

  private removeTitleTag(svg: string) {
    const titleRegex = /<title.*?\/title>/;
    const titlePosition = titleRegex.exec(svg);
    if (!titlePosition) {
      return svg;
    }
    const titleStart = titlePosition.index - 1;
    const titleStop = titleStart + titlePosition[0].length;
    return svg.slice(0, titleStart) + svg.slice(titleStop);
  }
}
