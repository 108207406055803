import {
    AfterViewInit,
    Component,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { BindObservable } from 'bind-observable';
import { fromEvent, Observable } from 'rxjs';
import { map, pairwise, startWith } from 'rxjs/operators';

@Component({
    template: `
        <div
            #navbarItemWrapper
            class="nav-padding"
            boundariesElement="window"
            [tooltip]="label"
            [tooltipAnimation]="true"
            [isDisabled]="!(hasOverflow$ | async)"
            placement="bottom"
            triggers="mouseenter:mouseleave"
            container="body"
        >
            <div class="d-grid gap-2">
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </div>
        </div>
    `,
})
export class NavbarItemWrapper implements AfterViewInit, OnDestroy {
    template: TemplateRef<HTMLElement>;
    label: string;

    @BindObservable()
    watchOverflow: boolean = true;
    watchOverflow$: Observable<boolean>;

    @ViewChild('navbarItemWrapper') navbarItemWrapper: ElementRef<HTMLDivElement>;

    @BindObservable()
    hasOverflow = false;
    hasOverflow$: Observable<boolean>;

    private _initialDevicePixelRatio = window.devicePixelRatio;

    constructor(private renderer2: Renderer2, private elementRef: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        const devicePixelRatio$ = fromEvent<Event>(window, 'resize').pipe(
            map(event => event.target['devicePixelRatio'])
        );
        const zoomIn$ = devicePixelRatio$.pipe(
            pairwise(),
            map(([prev, next]) => prev <= next),
        );

        if (this.watchOverflow) {
            requestAnimationFrame(() => {
                setTimeout(() => {
                    this.checkOverflow(false);
                    zoomIn$.pipe(startWith(true)).subscribe(zoomIn => this.checkOverflow(zoomIn));
                }, 300);
            });
        }

        this.hasOverflow$.subscribe(hasOverflow => {
            if (hasOverflow) {
                requestAnimationFrame(() => this.navbarItemWrapper.nativeElement.classList.add('only-icon'));
            } else {
                requestAnimationFrame(() => this.navbarItemWrapper.nativeElement.classList.remove('only-icon'));
            }
        });
    }

    ngOnDestroy(): void {}

    checkOverflow(zoomIn: boolean) {
        const el = this.navbarItemWrapper.nativeElement;
        const btn = el.querySelector('.btn');

        if (!btn) return;

        if (this.hasOverflow && zoomIn) return;
        if (!zoomIn && (window.devicePixelRatio / this._initialDevicePixelRatio) * 100 > 125) return;

        const deepest = this.deepest(Array.from(el.children), null);
        const last = deepest[deepest.length - 1];
        const deepestOffsetHeight = last['offsetHeight'] + last['offsetTop'];

        this.hasOverflow = btn['scrollHeight'] > btn['clientHeight'] || deepestOffsetHeight > btn['offsetHeight'];
    }

    private deepest(elements: Element[], selector: string): Element[] {
        let deepestLevel = 0,
            deepestChildSet = new Set<Element>();

        elements.forEach(parent => {
            let children = parent.querySelectorAll(selector || '*');
            children.forEach((child: Element) => {
                if (!child.firstElementChild) {
                    // Пропускаем элементы с классом "dont-hide"
                    if (child.classList.contains('dont-hide')) return;
                    let levelsToParent = 0;
                    let currentElement = child;

                    while (currentElement !== parent) {
                        currentElement = currentElement.parentElement;
                        levelsToParent++;
                    }

                    if (levelsToParent > deepestLevel) {
                        deepestLevel = levelsToParent;
                        deepestChildSet = new Set([child]);
                    } else if (levelsToParent === deepestLevel) {
                        deepestChildSet.add(child);
                    }
                }
            });
        });

        return Array.from(deepestChildSet);
    }

    private isVisible(element: Element): boolean {
        const style = getComputedStyle(element);
        return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0';
    }
}

@Directive({ selector: '[navbarItem]' })
export class NavbarItemDirective implements OnInit, OnDestroy {
    private _navbarItem: any;
    @Input()
    set navbarItem(value: any) {
        this._navbarItem = value;
    }

    private _label: string;
    @Input()
    set navbarItemLabel(value: string) {
        this._label = value;
    }

    private _watchOverflow = true;
    @Input()
    set navbarItemWatchOverflow(value: boolean) {
        this._watchOverflow = value;
    }

    private wrapperContainer: ComponentRef<NavbarItemWrapper>;

    constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}
    ngOnInit(): void {
        this.wrapperContainer = this.viewContainerRef.createComponent(NavbarItemWrapper);
        this.wrapperContainer.instance.template = this.templateRef;
        this.wrapperContainer.instance.label = this._label;
        this.wrapperContainer.instance.watchOverflow = this._watchOverflow;
    }
    ngOnDestroy(): void {
        this.wrapperContainer.destroy();
    }
}
