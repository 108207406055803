import { NgModule } from '@angular/core';

import { ThreeStateSwitchComponent } from './three-state-switch.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [ThreeStateSwitchComponent],
    exports: [ThreeStateSwitchComponent],
})
export class ThreeStateSwitchModule {
}
