import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmployeeModalService } from 'portal/modules/employee-modal/employee-modal.service';
import { UserPhotoModule } from 'portal/modules/user-photo/user-photo.module';
import { PhonePipeModule } from 'portal/pipes/phone-pipe.module';
import { ModalModule } from 'shared/modules/modal/modal.module';
import { EmployeeModalComponent } from './employee-modal/employee-modal.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        UserPhotoModule,
        PhonePipeModule,
        RouterModule,
    ],
    providers: [EmployeeModalService],
    declarations: [EmployeeModalComponent]
})
export class EmployeeModalModule {
}
