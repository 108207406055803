import { AfterContentInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { NamedTemplateDirective } from './template.directive';

export interface TemplatesMap<T = any> {
  [s: string]: TemplateRef<T>;
}

/*tslint:disable component-class-suffix*/
@Component({
  selector: 'app-recursive-templates',
  template: '<hr>',
})
export class RecursiveTemplates implements AfterContentInit {
  @ContentChildren(NamedTemplateDirective) templates: QueryList<NamedTemplateDirective>;

  @Input()
  private set templatesMap(tm: TemplatesMap) {
    this._externalTemplatesMap$.next(tm);
  }

  private _externalTemplatesMap$ = new BehaviorSubject<TemplatesMap>({});
  private _internalTemplatesMap$: Observable<TemplatesMap>;
  templatesMap$: Observable<TemplatesMap>;

  ngAfterContentInit() {
    this._internalTemplatesMap$ = this.templates.changes
      .pipe(
        startWith(''),
        map(() => this.templates
          .map(tm => ({ [tm.key]: tm.template }))
          .reduce((a, b) => ({ ...a, ...b }), {})),
        distinctUntilChanged(),
        shareReplay(1),
      );
    this.templatesMap$ = combineLatest([this._internalTemplatesMap$, this._externalTemplatesMap$]).pipe(
      map(([internal, external]) => ({ ...internal, ...external })),
    );
  }
}
