/*tslint:disable directive-selector*/
import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: 'ng-template[templateName]' })
export class NamedTemplateDirective {
  @Input()
  set templateName(key) {
    this.key = key;
  }

  key: string;

  constructor(public template: TemplateRef<any>) {}
}
