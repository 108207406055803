import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!value) { return ''; }
    const code = value.slice(1, 4);
    const firstChunk = value.slice(4, 7);
    const secondChunk = value.slice(-4);
    return `+7 (${code}) ${firstChunk}-${secondChunk}`;
  }

}
